.font-H1 {
    font-size: 24px;
    // tablet
    @media screen and (max-width: 1279px) {
        font-size: 18px;
    }
    // mobile
    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
}

.font-H2 {
    font-size: 20px;
    // tablet
    @media screen and (max-width: 1279px) {
        font-size: 17px;
    }
    // mobile
    @media screen and (max-width: 600px) {
        font-size: 15px;
    }
}

.font-H3 {
    font-size: 16px;
    // tablet
    @media screen and (max-width: 1279px) {
        font-size: 14px;
    }
}

.font-H4 {
    font-size: 14px;
    // tablet
    @media screen and (max-width: 1279px) {
        font-size: 13px;
    }
    // mobile
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
}

.font-H5 {
    font-size: 12px;
    // tablet
    @media screen and (max-width: 1279px) {
        font-size: 12px;
    }
    // mobile
    @media screen and (max-width: 600px) {
        font-size: 11px;
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
}