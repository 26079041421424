$main-color: #34a8be;
$main-color-26: #34a8be26;
$main-color2: #35a8be;
$main-color3: #3F9BC8;
$main-color4: #00ACAD;
$main-color2-10: #35a8be10;
$white:#ffffff;
$dark-white:#e9ecef;
$white-border:#dee2e6;
$dark-white2:#f6f7ff;
$dark-white3:#e4faff;
$dark-white4:#e6e6e6;
$dark-white5:#D8D8D8;
$dark-white6:#d9dad9;
$dark-white7:#ebeeef;
$dark-white8:#d8dadc;
$dark-white9:#c8c8c8;
$dark-white00:#FDFDFF;
$dark-white11:#f9f9f9;
$light-pink:#ffe7e7;
$black:#000000;
$black2:#101010;
$white-00:#ffffff00;
$box-shadow:rgba(0, 0, 0, 0.16);
$light-gray:#d4d4ea;
$light-gray2:#ced4da;
$light-gray3:#b3c2ca;
$light-gray4:#979797;
$gray:#495057;
$light-blue:#7674a1;
$light-blue-50:#7674a150;
$light-red:#ea5455;
$light-red-40:#ea545540;
$light-red2:#ffcfd0;
$heavy-red:#ac0004;
$light-red-15:#ea545515;
$dark-blue:#04004d;
$dark-blue-ed:#04004Ded;
$dark-blue2:#003F84;
$open-blue:#0057e7;
$green:#28c76f;
$green-40:#28c76f40;
$dark-green:#008744;
$light-green:#ccf5de;
$light-green2:#baffe5;
$light-green3:#9ee4ff;
$yellow:#ffc405;
$orang:#FF9F43;
$light-yellow:#fdf6c6;
$trqwaz:#5677fc;
$dark-trqwaz:#156b8d;
$gray-777:#777;
$gray-666:#666;
$gray-333:#333;
$dark-blue-la:#48647c1a;
$purple:#694382;
$light-purple:#ECCFFF;
$copper:#805B36;
$light-copper:#FFD8B2;
$brown:#8A5340;
$light-brown:#FEEDAF;
$labny:#23547B;
$light-labny:#B3E5FC;
$background-blue:#7367F016;
$background-red:#FF9F4316;
$background-red2:#d5909015;
$background-green:#28C76F16;
$auth-background:#c3c3c312;
$background-gray:#f8f8f7b8;
$gray-707070:#707070;
$border-gray:#d9ddfa79;
$border-light-gray:#48647c13;
$background-dark-red:#0f0f0f38;

