@font-face {
    font-family: montserrat-light-ar;
    src: url('~assets/fonts/montserrat-ar/ArbFONTS-Montserrat-Arabic-Light.ttf') format("truetype");
}

@font-face {
    font-family: montserrat-regular-ar;
    src: url('~assets/fonts/montserrat-ar/ArbFONTS-Montserrat-Arabic-Regular.ttf') format("truetype");
}

@font-face {
    font-family: montserrat-medium-ar;
    src: url('~assets/fonts/montserrat-ar/ArbFONTS-Montserrat-Arabic-Medium.ttf') format("truetype");
}

@font-face {
    font-family: montserrat-semibold-ar;
    src: url('~assets/fonts/montserrat-ar/ArbFONTS-Montserrat-Arabic-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: montserrat-thin-ar;
    src: url('~assets/fonts/montserrat-ar/ArbFONTS-Montserrat-Arabic-Thin.ttf') format("truetype");
}

main[dir="rtl"] {
    .ng-clear {
        margin-right: 6px;
    }

    @media (max-width: 426px) {
        .direction-rtl {
            direction: unset !important;
        }
    }

    .owl-carousel {
        direction: ltr;
    }

    .beneficiary-carousel {
        .owl-carousel {
            direction: rtl;
        }
    }

    table>tr>th,
    body .ui-table .ui-table-thead>tr>th,
    .table-striped tr>th,
    body .p-datatable .p-datatable-thead>tr>th,
    h2 {
        font-family: montserrat-semibold-ar;
    }

    .custome-pos-toolbar-grid li {
        direction: ltr;
    }

    .mr-12 {
        margin-left: 12rem;
        margin-right: 1rem !important;
    }

    .row.wrapper-tabel {
        margin-right: 30px;
        padding-left: 0 !important;
    }

    .ml-1 {
        margin-left: unset !important;
        margin-right: 0.25rem !important;
    }

    .mat-body p,
    .mat-body-1 p,
    .mat-typography p,
    p,
    .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text,
    .e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells>div,
    .session-blue-card,
    .calender-list div,
    .labelTab-2,
    .labelTab-4,
    .labelTab-3,
    input::-webkit-input-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder,
    ::-moz-placeholder {
        font-family: montserrat-regular-ar !important;
    }

    span.p-treenode-label {
        font-family: montserrat-regular-ar !important;
    }

    .card-characteristics>div,
    .card-characteristics p,
    .card-carousel p,
    .form-group,
    .e-header-day,
    .e-header-date .e-navigate,
    .ui-widget-content p,
    .cutom-mat-title,
    .p-widget-content p,
    .p-multiselect p,
    .box p {
        font-family: montserrat-regular-ar !important;
    }

    .mat-body p,
    .mat-body-1 p,
    .mat-typography p,
    .plan-container span,
    .e-schedule .e-vertical-view .e-date-header-wrap table tbody td,
    .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text,
    .e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells>div,
    .session-gray-card {
        font-family: montserrat-regular-ar !important;
    }

    .card-related-session span,
    .session-blue-card,
    .card-related-session,
    p,
    .implementation td span,
    .plan-container span,
    .added-box span,
    .session-gray-card {
        font-family: montserrat-regular-ar !important;
    }

    div.session-blue-card {
        font-family: montserrat-regular-ar !important;
    }

    .pi,
    .card-related-session .pi {
        font-family: "primeicons" !important;
    }

    a {
        font-family: montserrat-medium-ar !important;
    }

    .custome-pr .pl-1 {
        padding-left: 15px !important;
        padding-right: 0.25rem !important;
    }

    .custome-pr .pr-1 {
        padding-right: 15px !important;
        padding-left: 0.25rem !important;
    }

    .custome-show {
        top: 4px;
        position: absolute;
        left: unset;
        right: 4rem;
    }

    .progress-status {
        right: unset;
        left: 55px;
    }

    .pl-0,
    .px-0 {
        padding-left: unset !important;
        padding-right: 0 !important;
    }

    .custom-counter__txt {
        border-radius: 0 !important;
        // border-top-left-radius: 8px !important;
        // border-bottom-left-radius: 8px !important;
        // border-top-right-radius: 0 !important;
        // border-bottom-right-radius: 0 !important;
    }

    .custom-excute-btn-group {
        // border-top-left-radius: 0 !important;
        // border-bottom-left-radius: 0 !important;
        // border-top-right-radius: 8px !important;
        // border-bottom-right-radius: 8px !important;
        border-radius: 0 !important;
    }

    .btn-group button:last-child {
        border-radius: 0 8px 8px 0 !important;
    }

    .btn-group button:first-child {
        border-radius: 8px 0 0 8px !important;
        border-right: 1px solid #D4D4EA;
    }

    .planTable .ui-paginator,
    .planTable .p-paginator {
        text-align: right !important;
    }

    .delete-plan-pos {
        position: absolute;
        left: 2rem;
        right: unset;
    }

    .wrapper-slider {
        margin: 10px !important;
    }

    .card-characteristics p-checkbox {
        margin-left: unset !important;
        margin-right: 1rem !important;
    }

    .card-characteristics p-radiobutton {
        margin-left: unset !important;
        margin-right: 1rem !important;
    }

    .ml-3,
    .mx-3 {
        margin-right: 1rem !important;
        margin-left: unset;
    }

    .card-carousel__checkbox {
        left: unset;
        right: 40px;
        top: 55%;
    }

    .mr-1 {
        margin-right: unset !important;
        margin-left: 0.25rem !important;
    }

    .custom-posation {
        position: absolute;
        left: 5rem !important;
        right: unset !important;
    }

    .d-none-web .custom-posation2 {
        top: 57px;
    }

    .setname {
        font-family: montserrat-medium-ar;
    }

    .type-plane-details {
        font-family: montserrat-light-ar;
    }

    .status,
    .name-count {
        font-family: montserrat-regular-ar;
    }

    .planeType_part5 .back_button img {
        transform: rotate(270deg);
    }

    .planeType_part5 .back_button.d-flex {
        flex-direction: row-reverse;
    }

    .servicesFollowup_maininfo {
        mat-expansion-panel-header {
            height: auto !important;
        }
    }

    .offset-xl-9 {
        margin-right: 75%;
    }

    .total_Amount {
        text-align: left;
    }

    .rightClass {
        text-align: left;
    }

    .ml-70 {
        margin-right: 7%;
    }

    #DOB::-webkit-calendar-picker-indicator {
        color: $black;
        opacity: 1;
        display: block;
        background-position: left;
        height: 20px;
        position: absolute;
        left: 0;
        border-width: thin;
    }

    font-family: montserrat-regular-ar;

    .notifications-menu {
        margin-left: 2rem;
    }

    .slider-container .fa,
    .slider-container .far,
    .slider-container .fas {
        padding: 0 8px !important;
    }

    .slider-container .btn-outline-primary,
    .icon_name {
        font-family: montserrat-light-ar !important;
    }

    .header_internal_table,
    .module .module-type,
    .labelTab {
        font-family: montserrat-semibold-ar !important;
    }

    .slider-container .dropdown-item {
        font-family: montserrat-light-ar !important;
    }

    .dropdown-item {
        padding: 0.25rem 0.5rem !important;
    }

    .dropdown-menu {
        min-width: 12rem;
    }

    .ui-menu.ui-menu-dynamic,
    .p-menu.p-menu-dynamic {
        direction: rtl !important;
        text-align: right;
    }

    .button-2 mat-icon {
        right: 0px;
    }

    .ui-paginator .ui-paginator-current,
    .p-paginator .p-paginator-current {
        left: unset !important;
        right: 8rem !important;
    }

    .ui-paginator .ui-dropdown {
        // right: 3.2rem;
    }

    // .toolbar-grid li:first-child {
    //   margin-right: 0;
    // }
    .text-right {
        text-align: left !important;
    }

    button i {
        margin-left: 0.5rem;
    }

    .btn-danger i {
        margin-left: 0;
    }

    label,
    input,
    .mat-step-label,
    .custom-bread-crumb {
        font-family: montserrat-light-ar !important;
    }

    small {
        font-family: montserrat-light-ar !important;
    }

    .hint,
    .internalHeader,
    button,
    .label_header {
        font-family: montserrat-medium-ar;
    }

    .fontMeduim {
        font-size: 12px;
        font-family: montserrat-medium-ar !important;
    }

    .action_buutons {
        font-size: 13px;
        font-family: montserrat-medium-ar !important;
    }

    .mat-step-header {
        transform: rotate(180deg);
    }

    .ui-radiobutton .ui-radiobutton-box,
    .p-radiobutton .p-radiobutton-box {
        margin-right: -5px;
    }

    .ui-table .ui-table-thead>tr>th,
    .p-datatable .p-datatable-thead>tr>th {
        font-family: montserrat-regular-ar;
        font-weight: 500;
    }

    .ui-table .ui-table-tbody>tr>td,
    .p-datatable .p-datatable-tbody>tr>td {
        font-family: montserrat-light-ar !important;
    }

    .form-side .font-H1 {
        font-family: montserrat-medium-ar;
    }

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description,
    .child-details__container .child-detail__header,
    .expand-serviceBooking,
    .add-service-text,
    .serviceBooking_add {
        font-family: montserrat-light-ar !important;
    }

    .child-details__container .child-detail__Data,
    .child-details__container .child-detail__Data_more {
        font-family: montserrat-light-ar !important;
    }

    .border-right {
        border-left: 1px solid $white-border !important;
        border-right: 10px none $white-00 !important;
    }

    .stepper-data {
        transform: rotate(180deg);
    }

    .ui-menu .ui-menuitem,
    .p-menu .p-menuitem {
        direction: rtl;
    }

    .dropdown-menu .dropdown-item {
        direction: rtl !important;
        text-align: right !important;
    }

    .ui-menu .ui-menuitem-link .ui-menuitem-icon,
    .p-menu .p-menuitem-link .p-menuitem-icon {
        color: $gray-333;
        margin-left: 0.5em;
        margin-right: 0;
    }

    .dropdown-menu {
        direction: rtl !important;
        text-align: right !important;
    }

    .arrow {
        transform: rotate(-90deg);
    }

    ngb-datepicker-navigation-select>.custom-select {
        padding-right: 25px !important;
    }

    #externalDiagnosisLabel {
        padding-right: 25px;
    }

    /*=====================================================================================*/
    /*------------SHASH---------------*/
    /*=====================================================================================*/
    .slider-container {
        direction: rtl;
    }

    .ico-menu-stakeholder,
    .ico-menu-service,
    .ico-menu-registration,
    .ico-menu-crm,
    .ico-menu-companies,
    .ico-menu-hr,
    .ico-menu-session,
    .ico-menu-settings,
    .ico-menu-behavior {
        background-repeat: no-repeat;
        background-position: right 17px center;
        padding: 16px 50px 16px 17px;
        border-radius: 12px;
    }

    .ico-menu-service {
        padding-right: 57px;
    }

    .ico-menu-behavior {
        padding-right: 67px;
    }

    .dropdown-menu.show {
        direction: rtl !important;
    }

    .dropdown-menu .dropdown-item {
        direction: rtl !important;
        text-align: right !important;
    }

    .filter {
        /*-----Edit------*/
        right: 0 !important;
        left: unset !important;
        border-radius: 19px 0 0 19px;
    }

    .pl-60 {
        padding-right: 60px;
        padding-left: unset;
    }

    .ui-table .ui-table-tbody>tr>td:last-child,
    .p-datatable .p-datatable-tbody>tr>td:last-child,
    .table-striped tbody>tr>td:last-child {
        border-radius: 12px 0 0 12px !important;
    }

    .ui-table .ui-table-tbody>tr>td:first-child,
    .p-datatable .p-datatable-tbody>tr>td:first-child,
    .table-striped tbody>tr>td:first-child {
        border-radius: 0 12px 12px 0 !important;
    }

    .title,
    .ui-widget,
    .p-widget,
    .p-multiselect,
    .dropdown-menu .dropdown-item,
    .p-dialog {
        font-family: montserrat-regular-ar;
        text-align: right;
    }

    .form-container .form-group .password-visibility {
        float: left;
        right: -15px;
    }

    .form-container .form-group .remember-me {
        display: none;
    }

    .direction-forgerPass {
        direction: rtl;
    }

    input {
        text-align: right;
    }

    .direction-rtl {
        direction: ltr;
    }

    .direction-ltr {
        direction: rtl;
    }

    .direction-ltr.gridCount {
        direction: ltr;
    }

    .ui-dataview-layout-options.ui-selectbutton.ui-buttonset,
    .p-dataview-layout-options.p-selectbutton.p-buttonset {
        direction: ltr;
        display: flex;
    }

    .ui-dialog .ui-dialog-titlebar .ui-dialog-title,
    .p-dialog .p-dialog-titlebar .p-dialog-title {
        font-size: 18px !important;
    }

    .ui-confirmdialog.ui-dialog .ui-dialog-content,
    .p-confirm-dialog.p-dialog .p-dialog-content {
        font-size: 16px !important;
    }

    .mat-expansion-panel-header {
        height: 70px !important;
    }

    .mat-header-fit-content {
        height: fit-content !important;
    }

    ul.child-info {
        width: 100%;
    }

    .mr-30 {
        margin-left: 30px;
        margin-right: unset;
    }

    .ml-30 {
        margin-right: 30px;
        margin-left: unset;
    }

    .table-striped tr {
        font-family: montserrat-light-ar;
    }

    .table-striped tr>th {
        font-family: montserrat-regular-ar !important;
        font-size: 12px;
    }

    .table-striped tr>td {
        font-family: montserrat-light-ar !important;
        font-size: 12px;
    }

    .child-details .gend-cat,
    .child-details .shift-branch,
    .child-details .responsible,
    .child-details .gend-cat__attr,
    .child-details .shift-branch__attr,
    .child-details .responsible__attr {
        font-family: montserrat-light-ar !important;
    }

    input[type="radio"],
    input.radio {
        float: right;
        margin: 6px 2px 0 6px;
    }

    :host input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
    }

    .child-details .child-name {
        font-family: montserrat-medium-ar !important;
    }

    label {
        text-align: right;
        padding: 0px 0.5rem 0px 1em;
        direction: ltr;
    }

    .form-check-label {
        padding: 0;
    }

    p-radiobutton {
        flex-direction: row-reverse;
    }

    p-radiobutton label {
        padding: 0 !important;
    }

    .custome-pr label {
        padding: 0 15px;
    }

    .ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }

    .input-group-append .btn {
        left: -10px;
        right: unset;
    }

    .float-right {
        float: left !important;
    }

    .deleteBTN-position {
        left: 2.5rem;
        right: unset;
    }

    .ng-select {
        font-family: montserrat-light-ar;
    }

    .input-danger .alert-danger,
    .alert-danger,
    .form-title-style {
        font-family: montserrat-regular-ar !important;
        font-size: 12px;
        direction: rtl;
    }

    h6,
    h5 {
        direction: rtl !important;
        font-family: montserrat-regular-ar !important;
        font-size: 16px !important;
    }

    .float-left {
        direction: rtl;
        text-align: right;
        float: right !important;
    }

    .sendCode {
        bottom: 4.1rem;
        right: unset !important;
        left: 60px;
    }

    .border-left {
        border-right: 1px solid $white-border !important;
        border-left: 0 !important;
    }

    .text-success i {
        margin-left: 5px;
        margin-right: unset;
    }

    .child-details__container .child-detail__Data.head {
        margin-left: 1rem;
        margin-right: 0;
    }

    .dropdown-menu .dropdown-item {
        font-size: 12px;
        line-height: 16px;
        font-family: montserrat-regular-ar !important;
    }

    .custom-file-label::after {
        right: unset;
        left: -1px;
        border-radius: 8px 0 0 8px;
    }

    .custom-file label {
        padding: 10px 10px 0px 0 !important;
    }

    .mark-font__gray {
        font-size: 16px !important;
        font-family: montserrat-regular-ar !important;
    }

    .mark-font__blue {
        font-size: 16px !important;
        font-family: montserrat-regular-ar !important;
    }

    tr {
        font-family: montserrat-light-ar !important;
    }

    .child-details__container .parent .child {
        margin-left: 1rem;
        margin-right: 0 !important;
    }

    .custom-file-label::after {
        content: "إختر" !important;
    }

    #expand-addChild {
        font-family: montserrat-light-ar !important;
    }

    .font-H1,
    .title {
        font-size: 24px;
        font-weight: 500;
    }

    .button,
    .child-details__container .child-name,
    .child-details__container .child-id {
        font-family: montserrat-regular-ar !important;
    }

    .child-details__container .add-new-child .add-child-text {
        font-family: montserrat-regular-ar !important;
        font-size: 14px;
    }

    .filter .filter-sapn {
        left: 18% !important;
    }

    .ui-menu .ui-menuitem-link .ui-menuitem-text,
    .p-menu .p-menuitem-link .p-menuitem-text {
        font-size: 11px !important;
    }

    .wrapper-tabel {
        margin-left: 80px;
        padding-left: 0px !important;
        margin-right: 36px;
    }

    .ViewEmployee .wrapper-tabel {
        margin-left: 73px !important;
        padding-left: 0px !important;
        margin-right: 25px !important;
    }

    .company__main--name {
        font-family: montserrat-regular-ar;
    }
}

main[dir="rtl"] {

    .pi-caret-right,
    .pi-step-forward {
        transform: rotate(180deg);
    }

    .pi-caret-left,
    .pi-step-backward {
        transform: rotate(180deg);
    }

    .slider-container .dropdown-menu {
        text-align: right;
        right: 0;
    }
}

main[dir="rtl"] .ui-dialog .ui-dialog-titlebar-icons,
main[dir="rtl"] .p-dialog .p-dialog-titlebar-icons {
    float: left !important;
}

main[dir="rtl"] .ui-dialog .ui-dialog-footer,
main[dir="rtl"] .p-dialog .p-dialog-footer {
    text-align: left !important;
}

// for tabs
main[dir="rtl"] {

    // custom tab
    .mat-tab-label-content {
        font-family: montserrat-semibold-ar;
    }

    .mat-tab-group {
        flex-direction: row !important;
    }

    .mat-tab-header {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 0rem;
    }

    .mat-tab-label {
        margin-left: 0.3rem;
        opacity: 1 !important;
        justify-content: flex-start !important;
        min-width: 0 !important;
        width: 50px;
        transition: width 2s;
    }

    .mat-tab-labels .mat-tab-label {
        width: fit-content !important;
    }

    .arrow_item .arrowUP {
        float: left;
    }

    .arrow_item {
        text-align: -webkit-left;
    }

    .mat-tab-label-active {
        background-color: transparent;
        border-right: 3px solid $main-color;
        border-left: transparent;
    }

    .mat-tab-body-wrapper {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .icon_name {
        font-family: montserrat-light-ar !important;
    }

    .mat-tab-labels .mat-tab-label {
        margin-right: 0;
    }

    .sessionTabs {
        position: relative;

        &__img {
            position: absolute;
            top: 0px;
            right: 63px;
            z-index: 44;
            transform: rotate(180deg);
            cursor: pointer;
        }

        .active {
            transform: rotate(0deg) !important;
            right: 190px;
        }
    }

    .sessionTabs.service {
        position: relative;

        &__img {
            position: absolute;
            top: 0px;
            right: 63px;
            z-index: 44;
            transform: rotate(180deg);
            cursor: pointer;
        }

        .active {
            transform: rotate(0deg) !important;
            right: 144px;
        }
    }

    //end  custom tab
}

.modal-header {
    direction: rtl;
    font-family: montserrat-regular-ar !important;

    .close {
        position: absolute;
        left: 0;
    }

    .button {
        font-family: montserrat-regular-ar !important;
    }
}

.modal-body {
    font-family: montserrat-regular-ar !important;
}

.modal-footer {
    direction: rtl;

    .button {
        font-family: montserrat-regular-ar !important;
        font-size: 14px;
    }
}

main[dir="rtl"] .mat-step-label-selected::after {
    content: "";
    position: absolute;
    height: 100% !important;
    right: 1px;
}

main[dir="rtl"],
main[dir="rtl"] h4 {
    font-family: montserrat-regular-ar !important;
}

main[dir="rtl"] .dropdown-menu {
    text-align: right !important;
}

// check box aliment right in AR
main[dir="rtl"] {
    .serviceBorder {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-left: unset;
    }

    .custom-control-label::after,
    .custom-control-label::before {
        right: -1rem;
        left: unset;
    }

    .ui-multiselect-panel .ui-multiselect-item,
    .p-multiselect-panel .p-multiselect-item,
    .p-multi-select-panel .p-multi-select-item {
        text-align: right;
        direction: rtl;
    }
}

main[dir="rtl"] .dropdown .dropdown-menu .dropdown-item {
    text-align: right;
    direction: rtl;
}

main[dir="rtl"] .dropdown-menu.show {
    direction: rtl;
    text-align: right !important;
}

main[dir="rtl"] .mat-step-label i {
    margin-left: 5px;
}

main[dir="rtl"] .dropdown-item {
    text-align: right !important;
}

main[dir="rtl"] .fa-chevron-right:before {
    content: "\f053";
}

main[dir="rtl"] .dropdown-menu .dropdown-item {
    text-align: start !important;
    float: right !important;
    left: initial !important;
    right: 0 !important;
}

main[dir="rtl"] .toolbar-grid li {
    // margin-right: 1rem;
    margin-left: unset;
    color: $light-blue;
    font-weight: 500 !important;
}

main[dir="rtl"] .ml-4,
main[dir="rtl"] .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
}

@media (min-width: 900px) {
    main[dir="rtl"] {

        // alignment in view parent screen
        .parentComponent .header-box .col-12.parent,
        .childComponent .col-12.parent,
        .ViewEmployee .header-box .parent {
            padding-left: 0;
            padding-right: 5px;
        }

        .ui-paginator .ui-dropdown,
        .p-paginator .p-dropdown {
            right: 50px;
        }

        .parentComponent .ui-dataview-content,
        .childComponent .ui-dataview-content,
        .childComponent .p-dataview-content {
            padding-left: 6px !important;
            padding-right: 6px !important;
        }

        .ViewEmployee .ui-dataview-content,
        .ViewEmployee .p-dataview-content {
            padding-left: 14px !important;
            padding-right: 6px !important;
        }

        .parentComponent .pl-60,
        .childComponent .pl-60 {
            padding-left: unset;
        }

        .Employee mat-step-header:nth-of-type(1),
        .child-container mat-step-header:nth-of-type(1),
        .branch-container mat-step-header:nth-of-type(1) {
            padding-left: 0 !important;
        }
    }
}

@media screen and (max-width: 1400px) {
    main[dir="rtl"] {
        .offset-xl-9 {
            margin-right: 58.333333%;
        }
    }
}

@media screen and (max-width: 991px) {
    .custome-pr {

        .pr-1,
        .pl-1 {
            padding: 15px;
        }
    }

    main[dir="rtl"] .border-right {
        border-right: 0 !important;
        border-left: 0 !important;
        border-bottom: 1px solid $white-border !important;
        padding-bottom: 1rem !important;

        .wrapper {
            margin: 0 30px !important;
        }

        .offset-lg-7 {
            margin-right: 58.333333%;
        }
    }
}

main[dir="rtl"] {
    .control-label {
        direction: ltr;
        font-size: 16px;
    }
}

main[dir="rtl"] .planSet {
    .mat-expansion-panel-header {
        height: 130px !important;
    }
}

@media screen and (max-width: 767px) {
    main[dir="rtl"] {
        .serviceBorder {
            border-right: unset;
            border-left: unset;
        }
    }

    main[dir="rtl"] .filter {
        right: 95% !important;
    }

    .parent {
        margin-top: 25px !important;
        display: block;
        align-items: center;

        .title {
            margin: 0 0 1rem 0 !important;
        }

        .Contract .parent {
            margin-top: 0px !important;
        }
    }

    .wrapper-tabel {
        margin-left: 16px !important;
        padding-left: 0px !important;
        margin-right: 16px !important;
    }

    main[dir="rtl"] .ViewEmployee .wrapper-tabel {
        margin-right: 0px !important;
    }

    main[dir="rtl"] .filter .filter-sapn {
        left: 100% !important;
    }

    main[dir="rtl"] .pl-60 {
        padding-right: 0;
    }
}

@media screen and (max-width: 800px) {
    main[dir="rtl"] {
        .parentComponent .header-box {
            padding-left: 2px !important;
        }

        .ViewEmployee .header-box {
            padding-left: 0px !important;
        }

        .parentComponent .header-box .parent {
            padding-left: 0;
            padding-right: 10px !important;
        }

        .parentComponent .toolbar-grid li {
            margin-left: 8px;
        }

        .ViewEmployee .wrapper-tabel,
        .parentComponent .wrapper-tabel {
            // margin-left: 0px !important;
            padding-left: 0px !important;
            margin-right: 0px !important;
        }

        .Employee .title {
            margin-right: 1.1rem;
        }

        .mat-horizontal-stepper-header-container {
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .mat-horizontal-content-container {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        .child-container .header-box .parent {
            padding-right: 1.3rem;
        }
    }

    main[dir="rtl"] .child-container .childCheckMark {
        left: 1.5rem;
    }
}

@media (max-width: 426px) {
    main[dir="rtl"] .title {
        font-size: 18px;
    }

    main[dir="rtl"] .filter {
        right: 90% !important;
    }

    main[dir="rtl"] .custom-filter-pos {
        top: 9.4rem !important;
    }

    main[dir="rtl"] .parentComponent .filter {
        height: 87px !important;
    }

    main[dir="rtl"] .groupsComponent .filter {
        height: 30px !important;
    }

    main[dir="rtl"] .parentComponent .pl-60 {
        padding-right: 15px;
        padding-left: unset;
    }

    main[dir="rtl"] .parent.direction-forgerPass {
        margin-bottom: 2rem;
        margin-top: 1rem !important;
    }

    .ViewEmployee .wrapper-tabel {
        margin-left: 0px !important;
        padding-left: 0px !important;
        margin-right: 16px !important;
    }

    main[dir="rtl"] .ViewEmployee .toolbar-grid li {
        margin-right: 8px;
        margin-left: 8px;
        color: $light-blue;
        font-weight: 500 !important;
    }

    .ViewEmployee .header-box {
        margin-left: 0px !important;
    }

    main[dir="rtl"] .Employee .title {
        margin-right: 0.5rem;
    }

    main[dir="rtl"] .mat-horizontal-stepper-header-container {
        margin-right: 0rem;
        margin-left: 1rem;
    }

    main[dir="rtl"] .mat-horizontal-content-container {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    main[dir="rtl"] .child-container .childCheckMark {
        left: 1.5rem;
    }
}

@media (max-width: 376px) {

    main[dir="rtl"] .ViewEmployee .ui-dataview-header .col-12.text-right.direction-rtl,
    main[dir="rtl"] .ViewEmployee .p-dataview-header .col-12.text-right.direction-rtl {
        padding-left: 15px;
        padding-right: 15px;
    }

    main[dir="rtl"] .ViewEmployee .ui-dataview-header .child,
    main[dir="rtl"] .ViewEmployee .p-dataview-header .child {
        margin-right: 4px;
    }
}

main[dir="rtl"] {

    .bold,
    .panel-title,
    .child-detail__Data.bold {
        font-family: montserrat-semibold-ar !important;
    }
}

@media (max-width: 768px) {
    main[dir="rtl"] .offset-md-6 {
        margin-right: 50%;
    }

    main[dir="rtl"] .title {
        font-size: 14px;
    }

    main[dir="rtl"] .serviceBooking .wrapper-tabel {
        margin-left: 14px;
        padding-left: 0px !important;
        margin-right: 36px;
    }

    .thirdPartyNotice .wrapper-tabel.without-fliter,
    .serviceBooking .wrapper-tabel.without-fliter {
        margin-right: 0rem !important;
        margin-left: 1rem !important;
    }

    .thirdPartyNotice .wrapper-tabel.with-fliter,
    .serviceBooking .wrapper-tabel.with-fliter,
    .Contract .wrapper-tabel.with-fliter {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    main[dir="rtl"] .thirdPartyNotice {

        .ui-helper-clearfix .direction-rtl,
        .p-helper-clearfix .direction-rtl {
            direction: rtl;
        }
    }

    main[dir="rtl"] .serviceBooking {

        .ui-helper-clearfix .direction-rtl,
        .p-helper-clearfix .direction-rtl {
            direction: rtl;
        }
    }

    main[dir="rtl"] .SERVICEBOOKING {
        .col-7-custom {
            margin-left: 0rem !important;
            margin-right: 0 !important;
        }
    }
}

@media (max-width: 426px) {
    main[dir="rtl"] .offset-sm-0 {
        margin-right: 0%;
    }

    main[dir="rtl"] .mat-panel-title .title {
        font-size: 12px;
    }

    main[dir="rtl"] .mat-tab-group {
        flex-direction: column !important;
    }

    main[dir="rtl"] mat-tab-body-wrapper {
        border-radius: 0;
    }

    main[dir="rtl"] .mat-tab-body-wrapper {
        border-top-left-radius: 0;
    }

    main[dir="rtl"] .mat-tab-header {
        border-radius: 0rem;
    }

    main[dir="rtl"] .thirdPartyNotice {

        .ui-helper-clearfix .direction-rtl,
        .p-helper-clearfix .direction-rtl {
            direction: rtl;
            padding-right: 1rem;
            margin-right: 1rem;
        }

        .toolbar-grid {
            display: flex;
        }

        .toolbar-grid li:nth-child(2n) {
            margin-right: 0.7rem !important;
        }

        .toolbar-grid li:nth-child(3n) {
            display: none;
        }

        .mobile-gridCount {
            text-align: right;
        }

        .thirdPartyNotice .toolbar-grid {
            margin-bottom: 0rem;
        }
    }

    // service booking
    main[dir="rtl"] {
        .serviceBooking .toolbar-grid {
            margin-right: 1rem;
        }

        .serviceBooking .direction-ltr.gridCount {
            text-align: right !important;
        }
    }

    .SERVICEBOOKING .color-blue.cursor-pointer.ml-3.mr-3 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
}

@media (max-width: 375px) {
    .SERVICEBOOKING .color-blue.cursor-pointer.ml-3.mr-3 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        font-size: 13px;
    }

    .SERVICEBOOKING .color-blue.cursor-pointer {
        font-size: 13px;
    }
}

// body .ui-dialog {
//   text-align: right ;
//   direction: rtl ;
// }
// start action buttons  (mat-button-toggle) in services follow up
main[dir="rtl"] {
    .servicesFollowup_button {
        .mat-button-toggle-group-appearance-standard {
            border: solid 1px $main-color;
        }

        .mat-button-toggle-appearance-standard,
        .mat-button-toggle-group-appearance-standard {
            border-radius: 0px;
        }

        .mat-button-toggle-group-appearance-standard:first-child {
            border-radius: 0 10px 10px 0;
        }

        .mat-button-toggle-group-appearance-standard:last-child,
        .mat-button-toggle-group-appearance-standard:last-of-type {
            border-radius: 10px 0 0 10px;
        }
    }
}

@media (max-width: 768px) {
    main[dir="rtl"] .custome-pos-toolbar-grid {
        position: relative;
        margin-bottom: 0rem !important;
        top: 0rem;
        left: UNSET;
        float: right;
        direction: rtl;
    }
}

:host ::ng-deep .p-multiselect {
    min-width: 15rem;
    width: 18rem;
}

:host ::ng-deep .multiselect-custom {
    .p-multiselect-label {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }

    .country-placeholder {
        padding: 0.25rem;
    }
}

:host ::ng-deep {
    @media screen and (max-width: 640px) {
        .p-multiselect {
            width: 100%;
        }
    }
}

main[dir="rtl"] .servicePlans {
    .mat-expansion-panel {
        background: #F6F7FF;
    }

    .mat-expansion-panel-header:first-child {
        height: 95px !important;
    }

    .mat-expansion-panel-header.mat-expanded:first-child {
        background: #34A8BE;
        color: #fff !important;

        span {
            color: #fff !important;
        }

        .control-label {
            color: #fff !important;
        }
    }
}

main[dir="rtl"] .process-container {
    .mat-expansion-panel {
        background: #fff;
        border: 1px solid #D4D4EA;
    }

    .mat-expansion-panel-header:first-child {
        height: unset !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .mat-expansion-panel-header.mat-expanded:first-child {
        background: unset;
        color: unset !important;

        span {
            color: unset !important;
        }

        .control-label {
            color: #7674A1 !important;
        }
    }
}

main[dir=rtl] .addGroup .mat-expansion-panel-header {
    height: 150px !important;
}

main[dir='rtl'] .ChildDetails {
    right: unset;
    left: 100%;
}

main[dir='rtl'] .beneficiaryBox {
    .beforeBorder {
        border-left: 1px solid #D4D4EA;
        border-right: none;
    }
}

main[dir='rtl'] {

    .p-datatable-resizable .p-datatable-tbody>tr>td,
    .p-datatable-resizable .p-datatable-tfoot>tr>td,
    .p-datatable-resizable .p-datatable-thead>tr>th {
        text-align: right !important;
    }

    .p-datatable-resizable .p-datatable-tbody>tr>td,
    .p-datatable-resizable .p-datatable-tfoot>tr>td,
    .p-datatable-resizable .p-datatable-thead>tr>th {
        text-align: right !important;
    }
}

main[dir='rtl'] .p-paginator .p-dropdown {
    min-width: 3em;
    margin-left: 0.375em;
    position: absolute;
    top: -46px;
    right: 2rem;
    max-width: 4.8rem;
}

.customDataView.p-dataview.p-component.p-dataview-list .p-dropdown {
    top: -140px !important;
}

main[dir='rtl'] .p-multiselect-panel .p-multiselect-header {
    direction: rtl;
}

main[dir='rtl'] .p-tree .p-treenode-children {
    padding-right: 20px !important;
}

main[dir='rtl'] .p-dataview .p-paginator-bottom {
    direction: rtl;
}

main[dir='rtl'] .p-paginator .p-paginator-first,
main[dir='rtl'] .p-paginator .p-paginator-prev,
main[dir='rtl'] .p-paginator .p-paginator-next,
main[dir='rtl'] .p-paginator .p-paginator-last {
    transform: rotate(180deg);
}

main[dir='rtl'] .small-circule {
    margin-left: 5px;
    margin-right: 0;
}

main[dir='rtl'] .mat-radio-button .mat-radio-label,
main[dir='rtl'] .mat-checkbox-layout {
    direction: rtl !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #34a8be !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #34a8be !important;
}