/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import "alertifyjs/build/css/alertify.min.css";
@import "alertifyjs/build/css/themes/bootstrap.rtl.min.css";
@import 'bootstrap/scss/bootstrap';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/dist/css/bootstrap.css";
@import "animate.css/animate.min.css";
@import 'primeicons/primeicons.css';
@import "primeflex/primeflex.css";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import "styles/fonts.scss";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "styles/_globals.scss";
@import "styles/rtl-style.scss";
@import "styles/_variables.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

:root {
    // Colors
    --color-primary: #34A8BE;
    --color-surface-light: #ffffff;
    // Directional
    --left: left;
    --right: right;
    --dir: "rtl";
}

html,
body {
    min-height: 100%;
}

.unset-background {
    background-color: unset !important;
    border: 0 !important;
}

.small-circule {
    display: inline-block;
    height: 8px;
    width: 8px;
    padding: 0 !important;
}

body {
    background-size: cover;
    background-color: $dark-white2 !important;
}

body {
    font-family: 'montserrat-regular', 'arial', 'sans serif';
    text-align: left;
    scrollbar-color: var(--color-primary) var(--color-surface-light);

    .ui-widget {
        font-family: 'montserrat-regular', 'arial', 'sans serif';
    }
}

.bold {
    font-family: montserrat-bold
}

main[dir=rtl] .dropdown-item {
    text-align: right !important;
}

* {
    outline: none !important;
    box-shadow: none !important;
}

.rtl {
    direction: rtl;
    text-align: right;
}

.ltr {
    direction: ltr;
    text-align: left;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.d-inherit {
    display: inherit;
}

button:disabled {
    cursor: not-allowed !important;
}

.direction-ltr {
    direction: ltr;
}

.full-width {
    width: 100%;
}

// Dynamic Modal Hack
.bottom-left {
    position: absolute;
    bottom: 2%;
    left: 0%;
}

.bottom-right {
    position: absolute;
    bottom: 2%;
    right: 0%;
}

.top-left {
    position: absolute;
    top: 2%;
    left: 0%;
}

.top-right {
    position: absolute;
    top: 2%;
    right: 0%;
}

.bottom {
    position: absolute;
    bottom: 2%;
}

.top {
    position: absolute;
    top: 2%;
}

// TypoGraphy
@font-face {
    font-family: montserrat-light;
    src: url(assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
    font-family: montserrat-regular;
    src: url(assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
    font-family: montserrat-black;
    src: url(assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
    font-family: montserrat-semibold;
    src: url(assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: montserrat-medium;
    src: url(assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
    font-family: montserrat-bold;
    src: url(assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
    font-family: montserrat-light;
    src: url(assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}


/**** for filter ****/

.box p {
    margin-bottom: 1.4rem
}

.box {
    margin-bottom: 0.4rem !important;
}

.color-green {
    color: #00D385;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.add-assignment .mat-expansion-panel-content {
    padding: 0 10px 16px !important;
    border: 1px solid var(--bg);
    background: #F8F9FF 0% 0% no-repeat padding-box;
    border: 1px solid #F6F7FF;
    border-radius: 16px;
}

.add-assignment .mat-expansion-panel-header {
    padding: 0 5px !important;
    font-size: 18px;
    margin: 0 0px 0 12px;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.mat-select-arrow-wrapper {
    vertical-align: baseline !important;
}

.mat-select-panel {
    max-width: 10rem !important;
}

.tooltip-list {
    white-space: pre;
    font-size: small;
}

.blue-color {
    color: #34A8BE !important;
}

.auth-tree mat-tree button {
    border: unset !important;
    background: unset !important;
    color: #7674A1 !important;
}

main[dir=rtl] .auth-tree label {
    direction: inherit !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
    background-color: white !important;
    color: black !important;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon,
.p-checkbox .p-checkbox-box.p-highlight {
    font-size: 11px !important;
    color: white;
    background: #34A8BE;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
    color: #34A8BE !important;
}

.ui-tree .ui-treenode {
    margin-right: 19% !important;
    margin-left: 19% !important;
}

.ui-tree .ui-tree-wrapper {
    width: 200% !important;
}

// angular update fix styles
.breadcrumb {
    background: $dark-white2;
}

.p-dataview .p-dataview-header {
    background: $dark-white2;
    border: none;
}

.ui-helper-clearfix .row {
    height: 70px;
}

.p-datatable .p-datatable-thead>tr>th {
    border: none;
    background: $dark-white2;
    color: #7674a1;
}

.p-datatable .p-datatable-tbody>tr>td {
    border: none;
    text-align: start;
}

.p-dataview .p-dataview-content {
    background: transparent;
    overflow-x: scroll;
}

.p-datatable .p-datatable-tbody>tr {
    //border-radius: 9px;
    //margin: 10px 0 !important;
    border: 10px solid #f6f7ff;
    margin: 0 !important;
    padding: 0 !important;
}

.p-datatable .p-datatable-tbody>tr {
    color: #7674a1;
}

.p-paginator {
    background: unset;
    border: unset;
    direction: ltr;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    color: $main-color;
}

.p-paginator .p-paginator-pages {
    display: flex;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background: $main-color;
    border-color: $main-color;
    opacity: .5;
    margin: 5px;
    border-radius: 9px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $main-color !important;
    border-color: $main-color !important;
    opacity: 1;
    color: #fff !important;
}

.p-paginator .p-paginator-current {
    display: none;
}

.dropdown-item {
    color: #7674a1 !important;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: absolute;
}

.btn {
    border: none;
}

.p-paginator-rpp-options {
    border-radius: 9px;
    border: 1px solid $main-color;
    background: transparent;
    color: #000;
}

// .slider-container .btn-outline-primary {
//     width: 215px;
// }
// .p-grid.p-nogutter {
//     //display: none;
// }

.div-center {
    display: none;
}

.full-width {
    display: none;
}

.p-dialog .p-dialog-header {
    background: #fff;
    border-bottom: none;
}

.pi-exclamation-triangle {
    color: $light-red;
}

.p-confirm-dialog-message {
    color: $light-blue;
    font-weight: 900;
    font-size: 22px;
    margin: 0 20px;
}

.p-dialog .p-dialog-footer {
    border-top: none;
    text-align: left;
}

.p-confirm-dialog-accept {
    background: $light-red;
    padding: 10px 25px;
    border: 1px solid $light-red;
    border-radius: 9px;

    &:hover {
        background: $light-red;
        padding: 10px 25px;
        border: 1px solid $light-red;
        border-radius: 9px;
    }
}

.p-confirm-dialog-accept:enabled:hover {
    background: $light-red;
    padding: 10px 25px;
    border: 1px solid $light-red;
    border-radius: 9px;
}

.p-button-icon {
    display: none;
}

.p-confirm-dialog-reject {
    background: #fff;
    padding: 10px 25px;
    border: 1px solid #fff;
    border-radius: 9px;
    color: #000;
}

.p-confirm-dialog-reject:enabled:hover {
    background: #fff;
    padding: 10px 25px;
    border: 1px solid #fff;
    border-radius: 9px;
    color: #000;
}

body .ui-radiobutton {
    width: 12px !important;
}

.calender input {
    width: 100%;
}

main[dir=rtl] p-radiobutton label {
    margin-left: 16px !important;
}


.p-checkbox .p-checkbox-box {
    margin-right: -15% !important;
}


.cdk-overlay-container {
    z-index: 1000000;

    .mat-tooltip-show {
        background-color: #7674A1;
    }
}

.grid {
    // width: 100%;
}

.details_action_btns {
    min-width: 152px;
    display: flex;
    justify-content: space-between;
}

.case mat-expansion-panel-header {
    border-radius: 0;
    border-bottom: .5px solid #D4D4EA;
}

.gray-bg {
    background-color: #F6F7FF;
}