@import "fonts.scss";
@import "_variables.scss";

.font-size-12 {
    font-size: 12px;
}

body {
    font-size: 14px;
}

.mat-accordion .mat-expansion-panel {
    border-radius: 16px !important;
}

.card-characteristics {
    overflow-x: scroll;
}

.pi {
    font-family: 'primeicons' !important;
}

.d-contents {
    display: contents;
}

.p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
    border: unset !important;
}

.custom-dialog__child .ui-helper-clearfix::before,
.ui-helper-clearfix::after {
    display: block !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #34a8be !important;
}

.d-inline-table {
    display: inline-table !important;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.font-size-10 {
    font-size: 10px;
}

.height-36 {
    height: 36px !important;
}

.padding-10 {
    padding: 10px !important;
}

.gray-backgroud {
    background-color: #F6F7FF !important;
    background: #F6F7FF !important;
}

.text-decoration {
    text-decoration: underline !important;
}

.gray-box {
    background: #F6F7FF 0% 0% no-repeat padding-box;
    border: 1px solid #D4D4EA;
    border-radius: 8px;
    color: #7674A1;
    padding: 10px 13px;
    height: 48px;
}

.font-black {
    color: $black;
}

body .ui-table .ui-table-tbody>tr:nth-child(even),
body .p-datatable .p-datatable-tbody>tr:nth-child(even) {
    background-color: #fff;
}

.multi-color-table .ui-table .ui-table-tbody>tr:nth-child(odd),
.multi-color-table>tr:nth-child(odd),
.multi-color-table .p-datatable .p-datatable-tbody>tr:nth-child(odd) {
    background: #F6F7FF 0% 0% no-repeat padding-box !important;
    border-radius: 8px;
}

.multi-color-table .ui-table .ui-table-thead>tr>th,
.multi-color-table>tr>th,
.multi-color-table .p-datatable .p-datatable-thead>tr>th {
    color: $main-color !important;
}

.multi-color-table tr>td {
    border-radius: 0 !important;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p,
td span,
.plan-container span,
.e-schedule .e-vertical-view .e-date-header-wrap table tbody td,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text,
.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells>div,
.session-gray-card {
    font-family: montserrat-regular;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
    color: $light-blue;
}

.border-radius-15 {
    border-radius: 15px !important;
}

.no-border {
    border: unset !important;
}



.table-td-underline td {
    text-decoration: underline !important;
}

.actions button {
    border: none !important;
    background-color: unset !important;
    width: 1.7em !important;
    color: $light-blue !important;
}

body .ui-selectbutton .ui-button:first-child,
body .ui-selectbutton .ui-button:last-child {
    border-radius: 3px;
}

.gray-backgroud {
    .mat-expansion-panel {
        background-color: #F6F7FF !important;
        background: #F6F7FF !important;
    }

    .mat-expansion-indicator {
        background-color: $white;
        border: solid 1px $main-color;
    }
}

.custom-add {
    color: $main-color;
    font-size: 18px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    height: auto !important;
}

.wrap {
    flex-wrap: wrap;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons,
.e-schedule .e-vertical-view .e-header-cells.e-current-day,
.e-schedule .e-month-view .e-appointment .e-appointment-details .e-time,
.e-schedule .e-month-view .e-appointment .e-appointment-details .e-subject {
    color: #34A8BE !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    background: #34A8BE !important;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-input-group input,
.e-float-input.e-control-wrapper input,
.e-float-input.e-control-wrapper.e-input-group input,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-control-wrapper {
    border-radius: 0 !important;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-input-group:not(.e-filled) .e-input-group-icon::after,
.e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
    background: #34A8BE !important;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header,
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day,
.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check,
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #34A8BE !important;
}

tooltip-wrap {
    display: flex;
}

.tooltip-wrap .image {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/public-event.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
}

.tooltip-wrap .content-area {
    padding-left: 6px;
    font-size: 12px;
}

.tooltip-wrap .maintenance {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/maintenance.png');
}

.fabric .tooltip-wrap .maintenance,
.highcontrast .tooltip-wrap .maintenance {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/maintenance1.png');
}

.e-schedule-event-tooltip.tooltip-wrap.public-event {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/public-event.png');
}

.fabric .tooltip-wrap .public-event,
.highcontrast .tooltip-wrap .public-event {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/public-event1.png');
}

.tooltip-wrap .family-event {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/family-event.png');
}

.fabric .tooltip-wrap .family-event,
.highcontrast .tooltip-wrap .family-event {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/family-event1.png');
}

.tooltip-wrap .commercial-event {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/commercial-event.png');
}

.fabric .tooltip-wrap .commercial-event,
.highcontrast .tooltip-wrap .commercial-event {
    background-image: url('https://ej2.syncfusion.com/angular/demos/src/schedule/images/commercial-event1.png');
}

.tooltip-wrap .name {
    font-weight: 500;
    font-size: 14px;
}

.referral-notes-tooltip {
    position: relative;
    overflow: visible;
    font-family: montserrat-light-ar !important;
    max-width: 240px;
    width: 240px;

    .mdc-tooltip__surface {
        background-color: #fff !important;
        color: #04004D !important;
        box-shadow: 0px 0px 2px 0px #0000001F;
        box-shadow: 0px 4px 8px 0px #00000024;
        padding: 5px 7px;
        font-size: 12px;
        line-height: 20px;
        font-family: montserrat-light-ar !important;
        max-width: 240px;
        width: 240px;
    }
}


/* csslint ignore:end */

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
    background-color: #eee !important;
    border: 1px solid #34A8BE;
    color: #34A8BE;
}

.e-schedule .e-block-appointment {
    background: #D4D4EA !important;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: #FF9F43;
}

.e-schedule .e-read-only {
    background: #34A8BE !important;
}

.e-recurrenceeditor .e-days button {
    height: auto;
    width: fit-content;
}

.ui-dynamicdialog .button-padding {
    padding: 10px 30px !important;
}

.width-20 {
    width: 20%
}

.width-40 {
    width: 40%;
}

.width-30 {
    width: 30%;
}

.width-35 {
    width: 35%;
}

.width-15 {
    width: 15%;
}

.cancelBTN {
    color: $light-blue !important;
    background-color: unset !important;
    border: unset !important;
    font-weight: 600;
    border-radius: 8px;
    background-color: #d4d4ea;
    font-family: montserrat-medium;
    padding: 12px 30px;
}

.delete-btn__popup {
    background: #ea5455 0% 0% no-repeat padding-box !important;
    background-color: #ea5455 !important;
    color: #ffffff !important;
    border-radius: 8px;
    padding: 12px 30px !important;
    outline: 0 !important;
    border: solid 1px #ea5455;
}

.delete-btn__popup:hover {
    background-color: $white !important;
    color: #ea5455 !important;
}

.w-35 {
    width: 35%;
}

.w-150 {
    width: 200px;
}

.no-body-padding .mat-expansion-panel-body {
    padding: 0 !important;
}

.no-body-padding .expansion-panel__class {
    border: 1px solid #D4D4EA;
    padding: 10px;
}

body .ui-button.ui-button-text-icon-left .ui-button-text {
    padding: 12px 30px !important;
}

.beneficiary-carousel .owl-prev,
.beneficiary-carousel .owl-next {
    display: none !important;
}

.beneficiary-carousel .owl-item {
    width: unset !important;
}

.active .card-carousel {
    background: #34A8BE 0% 0% no-repeat padding-box !important;
    border: 1px solid #D4F8FF !important;
    color: #fff !important;
    opacity: 1 !important;
}

.labelTab-2 {
    font-size: 18px;
    color: $light-blue;
    font-family: montserrat-regular !important;
}

p-radiobutton {
    display: flex;
}

.text-right-webkit {
    text-align: -webkit-right !important;
}

.text-center-webkit {
    text-align: -webkit-center !important;
}

.labelTab-4 {
    font-size: 14px;
    color: $light-blue;
    font-family: montserrat-regular !important;
}

.labelTab-3 {
    color: $main-color;
    font-size: 10px;
    margin: 0 !important;
    font-family: montserrat-regular !important;
}

.mat-header-fit-content {
    height: fit-content !important;
}

.e-schedule.e-device .e-vertical-view .e-header-cells .e-header-date,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text {
    font-size: 12px !important;
}

.logs .ui-paginator .ui-dropdown,
.logs .ui-paginator .ui-paginator {
    display: none !important;
}

.d-content {
    display: contents;
}

.card-carousel__checkbox {
    position: absolute;
    left: 45px;
}

.card-characteristics {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D4D4EA;
    border-radius: 16px;
    padding: 1rem 2rem;

    p-checkbox {
        margin-right: 0.25rem !important;
    }
}

.result-p {
    color: #7674A1;
}

.cutom-mat-title {
    letter-spacing: 0px !important;
    color: #7674A1 !important;
    font-size: 12px !important;
    margin: 0 !important;
}

.card-related-session span,
.card-characteristics>div {
    font-family: montserrat-regular;
}

body a {
    font-family: montserrat-medium !important;
}

.border-0 {
    border: 0 !important;
    background: unset;
}

.card-characteristics p-checkbox {
    margin-right: unset !important;
    margin-left: 1rem !important;
}

.card-characteristics p-radiobutton {
    margin-right: unset !important;
    margin-left: 1rem !important;
}

.parent-underline .child-detail__Data {}

.ui-chips input {
    height: unset !important;
}

body .ui-chips>ul.ui-inputtext .ui-chips-token {
    background: #D4F8FF !important;
    color: $main-color;
}

// .pi-times:before {
//     color: $main-color;
// }

.session-blue-card {
    background-color: $main-color;
    color: $white;
    border-radius: 12px;
    padding: .5rem 1rem;
    width: 100%;
    font-family: montserrat-regular;
}

.session-gray-card {
    background-color: #d4d4ea4f;
    color: $main-color;
    border-radius: 12px;
    padding: .5rem 1rem;
    width: 100%;
}

body .ui-radiobutton .ui-radiobutton-box {
    border: 1px solid $main-color !important;
}

.ui-chips>ul.ui-inputtext {
    height: 48px;
    width: 100%;
    border-radius: 8px;
}

.ui-chips {
    width: 100%;
}

.white-background {
    background-color: $white !important;
    color: $white !important;
}

.table-responsive {
    border-radius: 10px;
    // overflow: scroll !important;
    -webkit-overflow-scrolling: touch !important;
    /* Lets it scroll lazy */
    padding: 0;
}

.rightClass {
    text-align: right;
}

.ml-70 {
    margin-left: 76%;
}

.linkDetails {
    color: $light-blue !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.internalHeader {
    font-family: montserrat-medium;
    font-size: 1rem;
    color: $dark-blue;
}

.header_internal_table {
    color: $light-blue;
    font-family: montserrat-semibold;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    display: block;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 32px !important;
}

.labelTab {
    font-family: montserrat-semibold;
    color: $main-color !important;
    font-size: 18px;
}

.label_header {
    color: $light-blue;
    font-size: 1.1rem;
    font-family: montserrat-medium;
}

@media screen and (max-width: 991px) {
    .login-backGround {
        display: none !important;
    }

    body .ui-dataview .ui-dataview-header {
        margin-bottom: 0px !important;
    }

    .ViewEmployee .wrapper-tabel,
    .thirdPartyNotice .wrapper-tabel {
        margin-right: 0px !important;
    }
}

.ui-sortable-column.ui-state-highlight {
    background-color: transparent !important;
    padding: 5px;
    box-sizing: border-box;
}

:host ::ng-deep button {
    margin-right: 0.25em;
}

.btn-link:hover {
    color: $main-color;
    text-decoration: underline;
}

.custom-width {
    max-width: 200px;
    word-wrap: break-word;
}

:host ::ng-deep .ui-message,
:host ::ng-deep .ui-inputtext {
    margin-right: 0.25em;
}

.addedBox__margin {
    margin: 1rem 1rem 1rem 0;
}

:host ::ng-deep .custom-message,
:host ::ng-deep img {
    align-self: center;
    font-size: 16px;
    margin-left: 0.5em;
}

.container-login {
    direction: ltr;
}

.dimmed {
    cursor: not-allowed !important;
    color: $light-blue-50 !important;
}

.owl-theme .owl-nav [class*="owl-"] {
    background: $main-color;
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: block !important;
}

.owl-theme .owl-dots {
    display: none !important;
}

.th-actions {
    width: 8em;
}

.cursor-pointer .width-50 {
    width: 50%;
}

.width-50 {
    width: 50%;
}

.filter .filter-sapn {
    left: 25% !important;
}

.filter .filter-img {
    left: 33% !important;
}

.owl-theme .owl-dots .owl-dot {
    display: none;
}

.owl-prev {
    position: absolute;
    top: 8px;
    left: 0;
}

.owl-next {
    position: absolute;
    top: 8px;
    right: 0;
}

.title-color {
    color: $dark-blue;
}

.center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

button:focus {
    outline: unset !important;
}

.no-scroll {
    overflow: hidden !important;
}

.underline {
    text-decoration: underline;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

body .ui-message {
    margin-top: 10px;
}

body .ui-inputtext.ng-dirty.ng-invalid {
    border: unset;
}

body .ui-message.ui-message-error {
    width: 100%;
}

input[type="checkbox"]:checked {
    background-color: $main-color !important;
    color: $white !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 48px !important;
}

.hidden {
    display: none;
}

.p-0 {
    padding: 0 !important;
}

p-multiSelect.multiSelect {
    max-width: 170px;
    min-width: 120px;
}

p-confirmDialog.confirmDialog,
p-dialog.Dialog {
    width: 50vw;
}

span {
    user-select: none;

    // color: $light-blue;
    &.can-select {
        user-select: unset;
    }
}

// button span , mat-step-header span {
//   color: unset !important;
// }
::-webkit-scrollbar {
    height: 0px;
    width: 3px;
}


/* Track */

::-webkit-scrollbar-track {
    background: var(--color-surface-light);
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: var(--color-primary);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-dark);
}

// Skeleton loading
.loading {
    position: relative;
    background-color: $dark-white;

    &.circle {
        border-radius: 50%;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        animation: loading 1.5s infinite;
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

// Appear with fade-in
.fade-in {
    animation: appear 0.75s linear forwards;
    opacity: 0;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.snack-bar-warring {
    background-color: $yellow !important;
    color: var(--color-on-error) !important;
}

.snack-bar-error {
    background-color: var(--color-error) !important;
    color: var(--color-on-error) !important;
}

.settings-list {
    .menu-item {
        &.single-item {
            .mat-expansion-panel-content {
                display: none !important;
            }
        }
    }
}

.form-container {
    input {
        width: 100%;
        height: 48px;
    }
}

body .ui-inputtext:enabled:hover:not(.ui-state-error) {
    border-color: unset;
}

.container {
    min-width: 100%;
}

.wrapper {
    margin: 0 80px !important;
}

.wrapper-slider {
    margin: 0 20px !important;
}

.wrapper-tabel {
    margin-right: 80px;
    padding-right: 0px !important;
    margin-left: 36px;
}

.thirdPartyNotice .wrapper-tabel {
    margin-right: 58px;
    padding-right: 0px !important;
    margin-left: 3px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-25 {
    margin-top: 25px;
}

input,
body .ui-dropdown,
body .ui-multiselect,
body .ui-paginator,
body .p-dropdown,
body .p-multiselect,
body .p-paginator {
    border-radius: 8px !important;
    border: 1px solid $light-gray;
    // padding: 5px;
    padding: 5px 28px 5px 5px;
    width: 100% !important;
    background: $white;
    transition: border-color 0.2s, box-shadow 0.2s !important;
}

.toolbar-grid .ui-multiselect,
.toolbar-grid .p-multiselect {
    width: fit-content !important;
    max-width: fit-content !important;
    min-width: fit-content !important;
}

select {
    height: 48px;
}

input,
body .ui-dropdown,
body .ui-paginator,
body .p-dropdown,
body .p-paginator {
    height: 48px;
}

body .ui-multiselect {
    border-radius: 4px !important;
}

body .ui-dropdown,
body .ui-multiselect,
select,
body .ui-paginator,
body .p-dropdown,
body .p-paginator,
body .p-multiselect {
    border-radius: 8px;
    border: 1px solid $light-gray;
    width: 100%;
    background: $white;
    transition: border-color 0.2s, box-shadow 0.2s;
    color: $light-blue;
}

.form-title-style {
    font-family: montserrat-regular;
    color: $dark-blue;
    margin-bottom: 30px;
    margin-top: 30px;
}

div.popup {
    position: absolute;
    background: $white;
    border: solid 0.5px $light-gray;
    box-shadow: 0 0px 6px 0 $box-shadow !important;
    z-index: 5;
}

// @-moz-document url-prefix() {
//   body .ui-dataview .ui-dataview-header {
//     overflow: hidden;
//   }
// }
div.popup:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 6px;
    color: $light-blue;
    padding: 4px;
    background: $white;
    border-right: none;
    border-bottom: none;
    transform: rotate(45deg);
    border: solid 0.5px $light-gray;
    box-shadow: 0 0px 6px 0 $box-shadow !important;
    z-index: -1;
    font-size: 12px;
    border-radius: 8px;
}

div.popup div.inner {
    padding: 8px;
    background: $white;
    z-index: 1;
    font-size: 12px;
    border-radius: 8px;
}

.toolbar-grid {
    display: flex;
}

.direction-rtl {
    direction: rtl;
}

.display-grid {
    display: grid;
}

.direction-ltr {
    direction: ltr;
}

.custome-pos-toolbar-grid li {
    direction: rtl;
}

.toolbar-grid li {
    margin-left: 8px;
    color: $light-blue;
}

.mat-expansion-panel-header {
    font-family: montserrat-regular;
}

div#a {
    top: 11.5rem;
    border-radius: 8px;
    left: 5.3rem;
}

.owl-theme .owl-nav .disabled {
    opacity: unset;
    cursor: not-allowed;
    background: $dark-white2 !important;
    color: $light-blue;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
    background: $white;
    color: $main-color !important;
    text-decoration: none;
    border: solid 1px $main-color !important;
}

.owl-theme .owl-nav .disabled:hover {
    background: $dark-white2 !important;
    color: $light-blue !important;
    border: unset !important;
}

input[type="date"] {
    color: $light-blue;
}

.ui-dataview .ui-dataview-header,
.ui-dataview .ui-dataview-footer,
.p-dataview .p-dataview-header,
.p-dataview .p-dataview-footer {
    padding: 0.5em 0 !important;
}

.session-group-list .ui-dataview .ui-dataview-header,
.ui-dataview .ui-dataview-footer,
.session-group-list .p-dataview .p-dataview-header,
.p-dataview .p-dataview-footer {
    padding: 0 !important;
}

body .ui-multiselect .ui-multiselect-label,
body .p-multiselect .p-multiselect-label {
    padding: 0;
    padding-right: 2em;
}

body .p-multiselect .p-multiselect-label {
    padding: 0;
}

.dropdowmn-noborder .ui-multiselect,
.dropdowmn-noborder .p-multiselect {
    padding: 12px;
    height: 48px;
    border-radius: 8px !important;
}

body .ui-dialog .ui-dialog,
body .p-dialog .p-dialog {
    width: 60vh;
    box-shadow: 0 0 6px 0 $box-shadow !important;
    border-radius: 8px;
}

body .ui-dialog .ui-dialog-content,
body .p-dialog .p-dialog-content {
    color: $light-blue;
}

.ui-confirmdialog .ui-dialog-content .ui-confirmdialog-icon,
.p-confirm-dialog .p-dialog-content .p-confirm-dialog-icon {
    font-size: 1.5em;
    margin-right: 0.5em;
    position: relative;
    top: 0.2em;
    display: none;
}

.ui-confirmdialog.ui-dialog .ui-dialog-content,
.p-confirm-dialog .p-dialog-content {
    padding: 1em;
    border-bottom: 0 !important;
    font-size: 20px;
}

body .ui-button.ui-button-text-icon-left .ui-button-text {
    font-size: 16px;
    border-radius: 8px;
}

body .ui-dialog .ui-dialog-titlebar,
body .p-dialog .p-dialog-titlebar {
    border: unset;
    border-bottom: 1px solid $white;
}

.btn-link {
    color: $main-color !important;
    width: unset;
}

.list-buttons {
    display: flex;
    text-align: right;
}

.added-box h5 {
    font-size: 14px !important;
    font-family: montserrat-semibold;
}

.added-box h6,
.added-box p {
    font-size: 14px !important;
    font-family: montserrat-regular;
    padding-left: 1rem !important;
}

.added-box span {
    font-size: 11px !important;
    font-family: montserrat-regular;
}

.child-details__container .added-box span,
.added-box.childaddress span {
    padding-left: 0 !important;
}

.ui-dialog .ui-button-icon-only .ui-button-icon-left,
.ui-button-text-icon-left .ui-button-icon-left,
.ui-button-text-icon-right .ui-button-icon-right,
.p-dialog .p-button-icon-only .p-button-icon-left {
    display: none;
}

body .ui-button.ui-button-text-icon-left .ui-button-text,
body .p-button.p-button-text-icon-left .p-button-text {
    padding: 14px 19px;
}

body .ui-message.ui-message-error,
body .p-message.p-message-error {
    background-color: unset !important;
    border-color: unset !important;
    margin: 0;
    padding: 0;
    color: $light-red;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    font-family: montserrat-light !important;
    border: 0;
    direction: ltr;
}

body .ui-message.ui-message-error .ui-message-icon,
body .p-message.p-message-error .p-message-icon {
    display: none;
}

.input-danger .alert-danger {
    background-color: unset !important;
    border-color: unset !important;
    margin: 0;
    padding: 0;
    color: $light-red;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    font-family: montserrat-light !important;
    border: 0;
    direction: ltr;
}

.text-success i {
    margin-right: 5px;
}

.child-details__container .child-detail__Data,
.child-details__container .child-detail__Data_more,
.child-details__container .child-detail__header {
    font-size: 14px !important;
}

.child-details__container .child-detail__Data_more {
    color: $dark-blue;
}

.child-details__container .child-detail__Data {
    color: $light-blue;
}

body .ui-dialog .ui-dialog-footer,
body .p-dialog .p-dialog-footer {
    border: unset;
    display: flex;
    justify-content: end;
    flex-direction: row-reverse;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title,
body .p-dialog .p-dialog-titlebar .p-dialog-title {
    font-size: 21px;
}

body .ui-dialog .ui-dialog-footer button,
body .p-dialog .p-dialog-footer button {
    border-radius: 8px;
}

body .ui-dialog .ui-dialog-titlebar,
body .p-dialog .p-dialog-titlebar {
    border: 1px solid $white;
    background-color: $white;
    color: $dark-blue;
    padding: 1em;
    font-weight: 700;
    border-bottom: 0 none;
}

// body .ui-dialog {
//   width: 60vh !important;
// }
body .ui-dialog a,
body .p-dialog a {
    color: $dark-blue !important;
}

.width-100 {
    width: 100%;
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
body .p-paginator-panel .p-paginator-items .p-paginator-item.ui-state-highlight,
body .p-paginator-panel .p-paginator-items .p-paginator-item-group.ui-state-highlight {
    color: $white;
    background-color: $main-color;
}

.dropdown-item i {
    margin-right: 5px;
}

.dropdown-item:hover {
    background-color: $main-color2-10;
    color: $main-color2;
}

body .ui-multiselect .ui-multiselect-trigger,
body .p-multiselect .p-multiselect-trigger {
    border-radius: 8px;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight,
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-state-highlight {
    color: $white;
    background-color: $main-color;
}

form label {
    color: $light-blue !important;
}

.panel-title,
mat-panel-title {
    @extend .font-H3;
    color: $main-color;
    font-family: montserrat-semibold;
}



#expand-addChild {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    font-family: montserrat-semibold;
}

::-webkit-input-placeholder {
    /* Edge */
    color: $light-gray !important;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active,
body .p-chkbox .p-chkbox-box.p-state-active {
    border-color: $main-color2 !important;
    background-color: $main-color2 !important;
    color: $white !important;
}

body .ui-chkbox .ui-chkbox-box,
body .p-chkbox .p-chkbox-box {
    width: 16px !important;
    height: 16px !important;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon,
body .p-chkbox .p-chkbox-box .p-chkbox-icon {
    font-size: 12px !important;
    color: $white;
}

body .ui-dropdown .ui-dropdown-trigger,
body .p-paginator .p-paginator-trigger {
    border-radius: 10px !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $light-gray !important;
}

::placeholder {
    color: $light-gray !important;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active,
body .p-radiobutton .p-radiobutton-box.p-state-active {
    border-color: $main-color !important;
    background-color: $main-color !important;
    color: $white;
}

body .ui-paginator .ui-dropdown .ui-dropdown-trigger,
body .ui-paginator .ui-dropdown .ui-dropdown-label,
body .p-paginator .p-dropdown .p-dropdown-trigger,
body .p-paginator .p-dropdown .p-dropdown-label {
    padding: 0;
}

span.p-element.p-dropdown-label.p-inputtext.ng-star-inserted {
    padding-inline-end: 18px;
}

body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover,
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover,
body .p-selectbutton .p-button.p-state-active:not(.p-state-disabled):hover,
body .p-selectbutton .p-button.p-state-active:not(.p-state-disabled):hover {
    background: $main-color 0% 0% no-repeat padding-box !important;
    color: $white !important;
}

::-webkit-input-placeholder {
    /* Edge */
    @extend .font-H3;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    @extend .font-H3;
}

::placeholder {
    @extend .font-H3;
}

.float-left {
    direction: ltr;
    text-align: left;
    float: left;
}

.parent {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
}

body .ui-inputtext,
body .p-inputtext {
    border: 1px solid $light-gray;
}

body .sessionCalendarInput .ui-inputtext,
body .sessionCalendarInput .p-inputtext {
    border: none;
}

.sessionCalendarInput {
    width: 30px;

    input {
        color: #fff;
    }
}



body .p-paginator-rpp-options>.ui-inputtext,
body .p-paginator-rpp-options>.p-inputtext {
    border: none;
}



.color-blue {
    color: $main-color !important;
}

.parent2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mr-30 {
    /*Shash - Add*/
    margin-right: 30px;
}

.ml-30 {
    /*Shash - Add*/
    margin-left: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

table,
.table-striped {
    width: 100%;
}

body .ui-dataview .ui-dataview-content,
body .p-dataview .p-dataview-content {
    border: unset !important;
    background-color: unset !important;
    overflow: visible;
}

.table-striped tbody tr:nth-of-type(odd),
body table>tr:nth-child(odd) {
    background-color: $dark-white2;
}

.collapse-seperate .table-striped tbody tr:nth-of-type(odd),
body table>tr:nth-child(odd) {
    background-color: $white !important;
}

.assignment-details .collapse-seperate2 tbody tr:nth-of-type(odd),
body table>tr:nth-child(odd) {
    background-color: $dark-white2 !important;
}

.custome-table_add-ass tbody tr:nth-of-type(odd),
body table>tr:nth-child(odd) {
    background-color: $white !important;
}

.width-50 {
    width: 50%;
}

.width-33 {
    width: 33%;
}

.e-schedule.e-device .e-vertical-view .e-header-cells .e-header-day,
.e-schedule.e-device .e-vertical-view .e-header-cells .e-header-day {
    font-size: 12px;
}

// .tr-collapse__container , .tr-collapse__container tr {
//   background-color: $dark-white2 !important;
//   background: $dark-white2 !important;
// }
.collapse-seperate .ng-select.ng-select-single .ng-select-container,
.dialog-table .ng-select.ng-select-single .ng-select-container,
.dialog-table input {
    height: 36px !important;
}

.table-striped tbody .red-row {
    background: $light-pink 0% 0% no-repeat padding-box !important;
    background-color: $light-pink !important;
}

.table-striped tbody .green-row {
    background: $light-green3 0% 0% no-repeat padding-box !important;
    background-color: $light-green3 !important;
}

.container-login input {
    padding: 10px !important;
    border-radius: 8px;
}

.container-login international-phone-number input {
    padding-left: 4rem !important;
}

international-phone-number {
    input {
        border-top-left-radius: 0 !important;
        position: relative;
        top: -3rem;
        padding-left: 4rem !important;
        z-index: 0;
    }

    .flagInput {
        z-index: 1;
    }
}

.over-width {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14rem;
    line-height: 2rem;
}

p-radiobutton label {
    margin-bottom: 0 !important;
}

international-phone-number .dropbtn {
    border: 1px solid $light-gray2 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    padding: 0.675rem 0.75rem !important;
}

international-phone-number .arrow-down {
    margin-top: 6px !important;
    margin-left: 8px !important;
    border-top: 5px solid $light-gray !important;
}

.p-relative {
    position: relative;
}

.form-container {
    width: 100%;

    .form-group {
        width: 100%;

        .forgot-password {
            @extend .font-H3;
            font-weight: 500;
            margin-inline-start: -12px;
            margin-top: 20px;
        }

        .login-btn {
            width: 100%;
            background-color: $main-color;
            border: solid 1px $main-color;
            color: $white;
            border-radius: 8px;
            padding: 11px;
            margin-top: 35px;
            @extend .font-H3;
        }

        .login-btn[disabled] {
            background-color: $box-shadow;
            border: solid 1px $box-shadow;
            cursor: not-allowed;
        }

        .remember-me {
            @extend .font-H3;
            top: 16px;
            left: 0;
            color: $black;
        }

        .password-visibility {
            position: relative;
            bottom: 36px;
            float: right;
            right: 15px;
            color: $light-gray;
            cursor: pointer;
        }

        .forgetPass {
            color: $main-color;
            @extend .font-H3;
            top: 16px;
            right: 0;
            cursor: pointer;
            float: right;
        }

        p {
            color: $light-blue;
            @extend .font-H3;
            margin-top: 30px;
            font-family: montserrat-regular !important;

            span {
                color: $main-color;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
}

body .ui-table .ui-table-tbody>tr>td,
body table>tr>td,
body .p-datatable .p-datatable-tbody>tr>td {
    border: unset !important;
}

.mt-15 {
    margin-top: 15px;
}

.ui-table table,
.table-striped tbody,
.p-datatable table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.p-datatable table {
    margin-top: 1rem;
    table-layout: fixed;
}

.filter {
    width: 44px;
    height: 107px;
    background: $white 0% 0% no-repeat padding-box;
    border-radius: 0px 19px 19px 0px;
    position: relative;
    left: 0;
    cursor: pointer;
    bottom: -9.5rem;
    z-index: 1;
}

body table>tr>td:first-child,
body .ui-table .ui-table-tbody>tr>td:first-child,
body .ui-table .ui-table-tbody>tr>td:first-child,
body .p-datatable .p-datatable-tbody>tr>td:first-child,
body .p-datatable .p-datatable-tbody>tr>td:first-child,
.table-striped tbody>tr>td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-color: $white;
}

body .ui-table .ui-table-tbody>tr>td,
body .p-datatable .p-datatable-tbody>tr>td,
.table-striped tbody>tr>td,
table>tr>td {
    border-color: $white;
}

body .ui-table .ui-table-tbody>tr>td,
body table>tr>td,
body .p-datatable .p-datatable-tbody>tr>td,
body .p-datatable .p-datatable-tbody>tr>td {
    padding: 14px;
    white-space: unset;
}

.pl-60 {
    padding-left: 60px;
}

body .ui-table .ui-table-tbody>tr>td:last-child,
body .p-datatable .p-datatable-tbody>tr>td:last-child,
.table-striped tbody>tr>td:last-child,
body table>tr>td:last-child {
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    border-color: $white;
}

body .ui-tree {
    border: unset !important;
}

body .ui-table .ui-sortable-column.ui-state-highlight,
body .p-datatable .p-sortable-column.p-state-highlight {
    background-color: transparent !important;
    color: $main-color;
}

body .ui-table .ui-sortable-column.ui-state-highligh:hover,
body .p-datatable .p-sortable-column.p-state-highligh:hover {
    background-color: $light-blue !important;
    color: $white;
}

body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary,
body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary {
    background-color: unset;
    color: $white;
    border: unset;
    padding: 0.571em 1em;
    text-align: center;
}

table {
    border-collapse: collapse;
}

table tr {
    margin: 10px 0;
    padding: 10px;
}

body table>tr>th,
body .ui-table .ui-table-thead>tr>th,
body .p-datatable .p-datatable-thead>tr>th,
.table-striped tr>th {
    border: unset;
    font-family: montserrat-bold;
    color: $light-blue;
    background-color: unset;
    white-space: break-spaces;
}

.serviceBooking .ui-table .ui-table-thead>tr>th:nth-child(5n),
.serviceBooking .ui-table .ui-table-thead>tr>th:nth-child(6n),
.serviceBooking .p-datatable .p-datatable-thead>tr>th:nth-child(5n),
.serviceBooking .p-datatable .p-datatable-thead>tr>th:nth-child(6n) {
    white-space: pre-line;
}

//start  add by nermen in new part of service booking
.servicesBooking_Child_details .ui-table .ui-table-thead>tr>th:first-of-type,
.servicesBooking_Child_details .p-datatable .p-datatable-thead>tr>th:first-of-type {
    width: 8rem;
}

.contract_multiselect .ui-multiselect-label,
.contract_multiselect .p-multiselect-label {
    font-size: 1rem !important;
}

.contract_multiselect .ui-state-default,
.contract_multiselect .p-state-default {
    padding-top: 12px;
    padding-bottom: 12px;
}

//end add by nermen in new part of service booking
.table th,
.table td {
    padding: 16px !important;
}

.e-btn,
.e-css.e-btn {
    background-color: $white !important;
    padding: 19px 16px !important;
    color: $light-blue !important;
    font-family: montserrat-medium !important;
}

.fontMeduim {
    font-size: 14px;
    font-family: montserrat-medium !important;
}

.e-dropdown-popup ul .e-item:hover {
    background-color: $dark-white2;
    color: $light-blue;
}

.e-dropdown-popup ul {
    padding: 8px;
    border-radius: 4px !important;
}

.e-dropdown-popup ul .e-item .e-menu-icon {
    color: $light-blue !important;
}

.e-btn.e-active,
.e-css.e-btn.e-active {
    background-color: $main-color !important;
    color: $white !important;
    border-radius: 12px !important;
    padding: 19px 16px !important;
}

.pi-user:before,
.pi-sign-out:before {
    color: $light-blue !important;
}

body .ui-menu.ui-shadow,
body .p-menu.p-shadow {
    border: 1px solid $white !important;
    box-shadow: 0 0px 6px 0 $box-shadow !important;
    margin-top: 16px !important;
}

.ui-menu,
.p-menu {
    width: 9.5em !important;
    color: $light-blue !important;
}

body .ui-menu .ui-menuitem:hover,
body .p-menu .p-menuitem:hover {
    background-color: $dark-white2 !important;
}

.ui-menu:after,
.p-menu:after {
    content: " ";
    content: " ";
    position: absolute;
    right: 93px;
    top: -15px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 15px solid $white;
}

.owl-carousel {
    padding-top: 10px;
}

.owl-carousel .owl-stage-outer {
    text-align: center;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto;
    overflow-x: hidden;
}

.owl-carousel .owl-item {
    overflow: visible !important;
}

.owl-carousel .owl-stage {
    width: 100% !important;
    display: -webkit-inline-box;
}

.beneficiary-carousel .owl-carousel .owl-stage {
    display: flex;
    flex-wrap: wrap;
}

body .ui-widget,
body .p-widget,
body .p-datatable {
    font-family: montserrat-regular;
    font-size: 14px;
}

.p-datatable-resizable .p-datatable-tbody>tr>td,
.p-datatable-resizable .p-datatable-tfoot>tr>td,
.p-datatable-resizable .p-datatable-thead>tr>th {
    overflow: hidden !important;
    white-space: initial !important;
}

.ui-menu .ui-menuitem,
.p-menu .p-menuitem {
    direction: ltr;
}

.btn-outline-primary:hover {
    color: $white !important;
    background-color: $main-color !important;
    border-color: $main-color !important;
    border-radius: 8px !important;
}

body .ui-menu .ui-menuitem-link .ui-menuitem-text,
body .p-menu .p-menuitem-link .p-menuitem-text {
    color: $light-blue !important;
}

body .ui-paginator,
body .p-paginator {
    background-color: unset !important;
    border: unset;
    text-align: center !important;
}

body .planTable .ui-paginator,
body .planTable .p-paginator {
    background-color: unset !important;
    border: unset;
    text-align: left !important;
    margin-top: 2rem;
}

body .ui-table .ui-table-tbody>tr,
body .p-datatable .p-datatable-tbody>tr,
body table>tr {
    @extend .font-H5;
    color: $light-blue;
    height: 54px;
}

.box {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 2px 8px 16px $dark-blue-la !important;
    border-radius: 16px;
    padding: 10px 24px;
    margin-bottom: 1rem;
}

.box .box {
    border: 1px solid #D4D4EA;
}

.no-shadow {
    box-shadow: unset !important;
}

.service-name__header.mat-expanded:focus,
.service-name__header.mat-expanded:hover,
.service-name__header.mat-expanded:active {
    background-color: $main-color;
    color: $white;
    background: $main-color !important;
}

.service-name__header.mat-expanded:focus span,
.service-name__header.mat-expanded:hover span,
.service-name__header.mat-expanded:active span {
    color: $white !important;
}

.deleteBTN {
    background: $light-red-15 0% 0% no-repeat padding-box;
    border-radius: 17px;
    width: 32px;
    padding: 3px 7px 5px 7px;
    position: relative;
    top: 50%;
    height: fit-content;
}

.delete-process-pos {
    top: 40%;
}

input[type="radio"],
input[type="checkbox"] {
    width: unset !important;
    margin-right: 5px;
    height: unset;
}

.child-info {
    float: left;
    display: flex;
}

.child-details__container .child-detail__header {
    color: $light-blue;
    margin-bottom: 7px !important;
    font-family: montserrat-medium;
}

.mt-45 {
    margin-top: 45px;
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-mdc-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: $main-color !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $main-color !important;
}

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-focus-icon-color: #212121;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #34a8be;
    --mdc-radio-selected-hover-icon-color: #34a8 b;
    --mdc-radio-selected-icon-color: #34a8be;
    --mdc-radio-selected-pressed-icon-color: #34a8be;
    --mat-mdc-radio-ripple-color: #000;
    --mat-mdc-radio-checked-ripple-color: #34a8be;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #34a8be;
    --mdc-checkbox-selected-hover-icon-color: #34a8be;
    --mdc-checkbox-selected-icon-color: #34a8be;
    --mdc-checkbox-selected-pressed-icon-color: #34a8be;
    --mdc-checkbox-unselected-focus-icon-color: #fff;
    --mdc-checkbox-unselected-hover-icon-color: #fff;
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

// start action buttons  (mat-button-toggle)
.servicesFollowup_button {
    .action_buutons {
        color: $main-color;
        font-size: 13px;
        font-family: montserrat-medium;
        margin-left: 2px;
        margin-right: 2px;
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 2rem !important;
        width: 7rem;
        text-align: center;
        padding: 0 12px !important;
    }

    .mat-button-toggle-group-appearance-standard {
        border: solid 1px $main-color;
    }

    .mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard {
        border-radius: 0px;
    }

    .mat-button-toggle-group-appearance-standard:first-child {
        border-radius: 10px 0 0 10px;
    }

    .mat-button-toggle-group-appearance-standard:last-child,
    .mat-button-toggle-group-appearance-standard:last-of-type {
        border-radius: 0 10px 10px 0;
    }

    .mat-button-toggle-button {
        // border-right: solid 1px $main-color !important;
    }
}

.plan_type {
    .mat-button-toggle-group-appearance-standard {
        // border: solid 1px $main-color;
    }

    .mat-button-toggle-button {
        // border-right: solid 1px $main-color !important;
    }

    // .mat-button-toggle-standalone,
    // .mat-button-toggle-group {
    //   border: 1px solid $main-color;
    //   border-radius: 5px;
    // }
    // .mat-button-toggle-label-content {
    //   border-right: 1px solid transparent !important;
    // }
    .mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard {
        border-radius: 0px;
    }

    .mat-button-toggle-group-appearance-standard:first-child // {

    //   &:lang(ar){
    //     border-radius: 0 10px   10px 0
    //     }
    //     &:lang(en){
    //       border-radius: 10px 0 0 10px;
    //     }
    // }
    .mat-button-toggle-group-appearance-standard:last-child,
    .mat-button-toggle-group-appearance-standard:last-of-type {
        &:lang(ar) {
            border-radius: 10px 0 0 10px;
        }

        &:lang(en) {
            border-radius: 0 10px 10px 0
        }
    }

    .mat-button-toggle-button {
        // border-right: solid 1px $main-color !important;
    }
}

// @media (max-width: 426px)
// {
//   .mat-button-toggle-group-appearance-standard{
//     width: 100%;
//   }
// }
.session_plan .plan_type .mat-button-toggle-label-content {
    border-right: 0px solid transparent !important;
}

// custom tab
.mat-tab-label-content {
    color: $main-color;
    font-size: 1.1rem;
    font-family: montserrat-semibold;
}

.mat-tab-group {
    flex-direction: row !important;
}

.mat-tab-labels {
    display: flex;
    flex-direction: column;
    min-height: 500px;
}

.mat-tab-header {
    border-bottom: none;
    background-color: $white;
    border-bottom-left-radius: 1rem;
    box-shadow: 0 0 6px 0 $box-shadow !important;
}

.mat-tab-header-pagination {
    display: none !important;
}

.mat-ink-bar {
    display: none;
}

.mat-tab-body-wrapper {
    flex: 1 1 auto;
}

.mat-tab-label {
    padding: 0 12px !important;
    background-color: $white;
    margin-right: 0.3rem;
    opacity: 1 !important;
    justify-content: flex-start !important;
    min-width: 0 !important;
    transition: width 2s;
    border-left: 3px solid transparent;
}

.mat-tab-nav-bar,
.mat-tab-header {
    border-bottom: none;
}

.mat-tab-label-active {
    background-color: transparent;

    // border-left: 3px solid $main-color;
    .mat-tab-label-content {
        color: $white !important;
    }

    .img-tab {
        color: $main-color;
    }

    .icon_name_show {
        color: $main-color;
    }
}

.mat-tab-body-wrapper {
    box-shadow: 0 0 6px 0 $box-shadow !important;
    background-color: $white;
    padding: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: $main-color !important;
}

.icon_name {
    color: $light-blue;
    font-size: 14px;
    font-family: montserrat-light !important;
    display: none;
    transition: display 2s;
}

.icon_name_show {
    display: block;
}

.img-tab {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

@media (max-width: 426px) {
    .mat-tab-group {
        flex-direction: column !important;
    }

    .mat-expansion-panel-header.mat-expanded,
    .mat-expansion-panel-header.mat-expanded {
        margin-bottom: 2px !important;
    }

    .mat-tab-labels {
        display: flex;
        flex-direction: row;
        min-height: 60px;
        overflow-x: scroll;
    }

    .mat-tab-header {
        border-bottom-left-radius: 0rem;
        overflow-x: scroll !important;
    }

    .mat-tab-label-container,
    .mat-tab-header,
    .mat-tab-labels {
        overflow-x: scroll !important;
    }

    .mat-tab-body-wrapper {
        border-top-right-radius: 0rem;
    }

    .icon_name_show,
    .icon_name {
        display: block;
    }

    .mat-tab-label-active {
        border-left: 1px solid transparent;

        // border-top: 2px solid $main-color;
        .icon_name {
            color: $main-color;
        }
    }
}

.ui-dialog-mask.ui-widget-overlay,
.p-dialog-mask.p-widget-overlay {
    text-align: left;
}

//end  custom tab
// end action buttons  (mat-button-toggle)
//status
.In-Progress,
.inprogress,
.Approved,
.AutoApproved {
    background-color: $light-green;
    color: $green;
}

.Pending,
.pending {
    color: $yellow;
    background-color: $light-yellow;
}

.On-Hold,
.onHold {
    color: $orang !important;
    background-color: $gray;
}

.Completed {
    color: $trqwaz;
    background-color: $white;
    border: 1px solid $trqwaz;
}

.Planned,
.planeed {
    color: $dark-trqwaz;
    background-color: $light-green2;
}

.Canceled,
.Reject {
    background-color: $light-red2;
    color: $light-red;
}

.NotStarted,
.NotUsed {
    color: $white !important;
    background-color: $dark-blue;
}

.planeed,
.NeedMoreInfo {
    color: $dark-trqwaz;
    background-color: $light-green2;
    width: 150px;
}

.Canceled {
    background-color: $light-red2;
    color: $light-red;
}

.Replaced {
    color: $main-color;
    background-color: $dark-white3;
}

.Transferred {
    color: $black2;
    background-color: $dark-white4;
}

.AutoReject {
    background-color: $heavy-red;
    color: $white;
}

// end status
.deleteBTN-position {
    position: absolute;
    right: 2.5rem;
    bottom: 1.2rem;
}

.ui-paginator,
.p-paginator {
    text-align: center;
}

.ui-paginator .ui-dropdown {
    min-width: 3em;
    margin-left: 0.375em;
    position: absolute;
    top: -38px;
    left: 3rem;
    max-width: 4rem;
}

.p-paginator .p-dropdown {
    min-width: 3em;
    margin-left: 0.375em;
    position: absolute;
    top: -46px;
    left: 3rem;
    max-width: 4.5rem;
}

.default-paginator .ui-paginator .ui-dropdown,
.default-paginator .p-paginator .p-dropdown {
    display: none;
}

.ui-dropdown-label-container,
.p-dropdown-label-container {
    margin-right: 25px !important;
}

.red-border-class {
    border: 1px solid $light-red;

    .ng-select-container {
        border: 1px solid $light-red !important;
    }
}

.ui-selectbutton,
.p-selectbutton {
    height: 34px;
}

.dropdown-red-border {

    .ng-select-container,
    .p-multiselect {
        border: 1px solid $light-red !important;
    }
}

body .ui-paginator .ui-dropdown,
body .p-paginator .p-dropdown {
    margin-left: 5px;
}

@media (max-width: 991px) {
    .mat-horizontal-stepper-header {
        margin: 0 -20px !important;
    }

    .send-to-parent {
        display: none;
    }

    p-table {
        overflow: scroll !important;

        /* this class is used for each table (td) tag to give fixed width to avoid td collapse on mobile view */
        .data-header {
            width: 100px !important;
        }

        .table-head>th {
            width: 100px !important;
        }
    }

    .box {
        padding: 16px;
    }

    .wrapper {
        margin: 0 7px !important;
    }

    .wrapper-tabel {
        margin-left: 30px;
        padding-left: 0px !important;
        margin-right: 36px;
    }

    .header-box {
        margin-top: 20px !important;
        margin-bottom: 5px;
        padding-left: 10px !important;
    }

    .ui-menu,
    .p-menu {
        width: auto !important;
    }
}

.backButton {
    border: 1px solid $light-gray;
    border-radius: 8px;
    background-color: $light-gray;
    color: $light-blue;
    font-family: montserrat-medium;
    padding: 12px 72px;

    mat-icon {
        position: relative;
        right: 5px;
    }
}

.child-img img {
    width: 55px;
    height: 55px;
    margin: 0 5px;
}

.nextButton,
.button-padding {
    padding: 12px 72px !important;
}

.color-red {
    color: $light-red;
}

.custom-paginator .ui-dropdown,
.custom-paginator .p-dropdown {
    position: absolute;
    top: 1rem;
    left: 42px;
}

.custom-paginator {
    margin-top: 21rem;
    margin-left: 28rem;
}

body .ui-selectbutton .ui-button.ui-state-active .ui-button-icon-left,
body .p-selectbutton .p-button.p-state-active .p-button-icon-left {
    color: $white !important;
}

body .ui-dialog .ui-dialog-content,
body .p-dialog .p-dialog-content {
    border: none !important;
    overflow: visible;
}

.modelParent .ui-dialog .ui-dialog-titlebar-icons,
.modelParent .p-dialog .p-dialog-titlebar-icons {
    position: relative;
    bottom: 2rem;
}

body .ui-dialog .ui-dialog-footer button,
body .p-dialog .p-dialog-footer button {
    background-color: $main-color2;
    color: $white;
}

body .ui-dialog,
body .p-dialog {
    padding: 0;
    box-shadow: 0 0 6px 0 $box-shadow !important;
}

.button {
    border: 1px solid $main-color;
    border-radius: 8px;
    background-color: $main-color;
    color: $dark-white2;
    font-family: montserrat-medium;
    padding: 12px 35px;

    mat-icon {
        position: relative;
        right: 5px;
    }
}

.button:hover {
    background-color: $dark-white2;
    color: $main-color;
    box-shadow: 2px 8px 16px $dark-blue-la !important;
}

.button-2 {
    border: 1px solid $main-color;
    border-radius: 8px;
    background-color: $dark-white2;
    color: $main-color;
    font-family: montserrat-medium;
    padding: 12px 35px;

    mat-icon {
        position: relative;
        right: 5px;
    }
}

.button-2:hover {
    background-color: $main-color;
    color: $dark-white2;
    box-shadow: 2px 8px 16px $dark-blue-la !important;
}

.button-3 {
    border: 1px solid $main-color !important;
    border-radius: 8px;
    background-color: $white !important;
    color: $main-color !important;
    font-family: montserrat-medium;
    padding: 12px 35px;
    height: 48px;

    mat-icon {
        position: relative;
        right: 5px;
    }
}

.button-red {
    border: 1px solid $light-red;
    border-radius: 8px;
    background-color: $white;
    color: $light-red;
    font-family: montserrat-medium;
    padding: 4px 15px !important;
    height: 36px;
    font-size: 12px;

    mat-icon {
        position: relative;
        right: 5px;
    }
}

.custom-add-btn {
    border: 1px solid $main-color;
    border-radius: 8px;
    background-color: $white;
    color: $main-color;
    font-family: montserrat-medium;
    padding: 12px;
    font-size: 35px;
    height: 48px;

    span {
        color: $main-color !important;
    }

    mat-icon {
        position: relative;
        right: 5px;
    }
}

button i {
    margin-right: 5px;
}

.btn-danger i {
    margin-right: 0;
}

.button-3:hover {
    background-color: $main-color !important;
    color: $dark-white2 !important;
    box-shadow: 2px 8px 16px $dark-blue-la !important;
}

.button-red:hover {
    background-color: $light-red;
    color: $dark-white2;
}

.added-box {
    background: $main-color-26 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: $main-color;
    display: inline-block;
}

.gray-color {
    color: $light-blue;
}

.color-red {
    color: $light-red !important;
}

.btn-danger {
    color: $light-red !important;
    background-color: $light-red-15 !important;
    border-color: transparent !important;
    border-radius: 8px !important;
    padding: 3px 8px !important;
}

.btn-primary {
    color: $main-color !important;
    background-color: $main-color-26 !important;
    border-color: transparent !important;
    border-radius: 8px !important;
    padding: 3px 2px !important;
}

.custom-control-input:checked~.custom-control-label::before {
    color: $white;
    border-color: $main-color2 !important;
    background-color: $main-color2 !important;
}

body .ui-slider .ui-slider-range,
body .p-slider .p-slider-range {
    background-color: $main-color;
}

body .ui-slider.ui-slider-horizontal .ui-slider-handle,
body .p-slider.p-slider-horizontal .p-slider-handle {
    cursor: grab;
}

.mb-15 {
    margin-bottom: 15px;
}

.btn {
    padding: 0.575rem 0.75rem;
}

.mark-font__gray {
    color: $light-blue !important;
    font-size: 18px !important;
    font-family: montserrat-bold !important;
}

.mark-font__blue {
    color: $dark-blue !important;
    font-size: 18px !important;
    font-family: montserrat-bold !important;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 48px;
    padding: 0.7rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: $gray;
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: 8px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 47px;
    padding: 0.7rem 0.75rem;
    line-height: 1.5;
    color: $light-blue;
    content: "Choose";
    background-color: $dark-white;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

input:disabled {
    color: rgb(170, 170, 170) !important;
    cursor: not-allowed !important;
    // background-color: #f8f8f7b8 !important;
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #fff;
    opacity: 0.5;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: $gray;
    background-color: unset;
    border-color: unset;
}

.p-15 {
    padding: 15px;
}

.child-details .childImg {
    width: 80px;
    height: 80px;
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
}

.radio-item input[type="radio"] {
    display: none;
}

.radio-item label {
    color: $gray-666;
    font-weight: normal;
}

.radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid $main-color2;
    background-color: $white;
}

.radio-item input[type="radio"]:checked+label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    border: 2px solid $main-color2;
    background: $main-color2;
}

.files-conainer {
    display: flex;

    li {
        margin-right: 1rem;

        &:first-child {
            margin-right: 0;
        }
    }
}

.card {
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid $light-gray;
    transition: 0.3s;
    width: 130px;
    height: 150px;
    border-radius: 4px;

    .pdf {
        background: $light-red 0% 0% no-repeat padding-box;
        border-radius: 5px;
        width: 48px;
        height: 52px;
        margin: 35px auto 16px auto;
        text-align: center;
        align-items: center;
        color: $white;
        font-size: 14px;
        padding: 10px 0;
    }

    .fileName {
        border-top: solid 1px $light-gray;
        color: $light-blue;
        padding-top: 5px;
    }
}

.card:hover {
    box-shadow: 0 8px 16px 0 $black2;
}

.mat-expansion-panel-content {
    font: inherit;
}

.p-70 {
    padding: 0 70px;
}

button {
    display: flex;
    align-items: center;
    justify-content: center;

    mat-icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
}

mat-step-header .mat-step-icon-not-touched span,
mat-step-header .mat-step-icon span,
mat-step-header .mat-step-icon-not-touched .mat-icon,
mat-step-header .mat-step-icon .mat-icon {
    display: none;
}

.mat-step-header {
    background-color: transparent !important;
    margin-left: -23px;
    padding: 0 25px !important;

    .mat-step-icon {
        visibility: collapse;
        display: none;
    }

    height: 50px !important;
}

.zindex-2 {
    z-index: 2 !important;
}

.btn-primary {
    color: $white;
    background-color: $main-color2;
    border-color: $main-color2;
}

.mt-25 {
    margin-top: 25px;
}

.mat-step-label-selected {
    pointer-events: none;
}

.owl-theme .owl-nav [class*="owl-"] {
    padding: 5px;
}

.exportBTN {
    background: $white 0% 0% no-repeat padding-box !important;
    border: 1px solid $light-gray !important;
    border-radius: 4px;
    color: $light-blue;
    padding: 0.35rem 0.5rem;
    font-size: 14px;
}

body .ui-paginator .ui-dropdown,
body .p-paginator .p-dropdown {
    border: 1px solid $light-gray;
    border-radius: 4px;
}

body .ui-multiselect .ui-multiselect-label,
body .p-multiselect .p-multiselect-label {
    color: #333 !important;
    font-size: 14px;
    font-weight: 400;
    font-family: montserrat-light-ar;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #999 !important;
}

.dropdown-item {
    color: $light-blue;
    font-size: 12px;
}

.pr-0 {
    padding-right: 0 !important;
}

.ui-multiselect-panel .ui-multiselect-item span,
.p-multiselect-panel .p-multiselect-item span {
    font-size: 12px;
}

// check box aliment right in AR
.ui-multiselect-items,
.p-multiselect-items {
    direction: ltr;
}

.ui-multiselect-panel .ui-multiselect-item,
.p-multiselect-panel .p-multiselect-item {
    text-align: left;
}

.p-multiselect-trigger {
    width: unset !important;
}

.p-multiselect-trigger-icon {
    color: #999 !important;
    font-size: 11px !important;
}

.mat-button-toggle-checked {
    background-color: #fff !important;
}

.ng-arrow-wrapper {
    padding-inline-end: 12px !important;
}

// checkbox in multi selected background
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: $main-color2;
}

.mat-step-label {
    border-radius: 0 !important;
    height: 100% !important;
    background-color: $dark-white !important;
    padding: 15px 40px !important;
    width: 100%;
    color: $gray-777 !important;
    font-family: montserrat-regular;

    i {
        color: $gray-777;
        padding-right: 10px;
    }
}

.mat-step-header .mat-step-label.mat-step-label-selected {
    color: $white !important;
}

.mat-step-header .mat-step-label.mat-step-label-selected i {
    color: $white;
}

mat-step-header:nth-of-type(1) .mat-step-label {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

mat-step-header:nth-of-type(2) .mat-step-label {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

mat-step-header:nth-of-type(3) .mat-step-label {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

mat-step-header:nth-of-type(1) .mat-step-label::before {
    height: 100% !important;
    border-left: inherit;
    border-radius: 20px 20px;
    border: 2px solid $main-color;
}

mat-step-header:nth-of-type(1) .mat-step-label-active::before {
    height: 100% !important;
    border-left: inherit;
    border-radius: 0px;
    border: 0 solid $green;
}

.branch-container mat-step-header:nth-of-type(2) .mat-step-label::after {
    display: none;
}

.employee-container mat-step-header:nth-of-type(3) .mat-step-label::after {
    display: none;
}

.sessionType mat-step-header:nth-of-type(3) .mat-step-label::after {
    display: block;
}

.sessionType mat-step-header:nth-of-type(6) .mat-step-label::after {
    display: none;
}

.child-container mat-step-header:nth-of-type(6) .mat-step-label::after {
    display: none;
}

.mat-step-label::after {
    content: "";
    position: absolute;
    height: 100% !important;
    right: 0px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid $dark-white;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.mat-step-label::before {
    content: "";
    position: absolute;
    height: 100% !important;
    left: 23px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid $dark-white2;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.mat-step-label-selected {
    background-color: $main-color !important;
}

.ui-sortable-column.ui-state-highlight,
.p-sortable-column.p-state-highlight {
    background-color: transparent !important;
}

.ui-sortable-column.ui-state-highlight span,
.p-sortable-column.p-state-highlight span {
    background-color: $main-color;
    padding: 5px 10px;
    color: $white;
}

.stepDone .mat-step-label {
    border-radius: 0 !important;
    height: 100% !important;
    background-color: $green !important;
    padding: 15px 40px !important;
    width: 100%;
    color: $gray-777 !important;
    font-family: montserrat-regular;

    i {
        color: $gray-777;
        padding-right: 10px;
    }
}

.stepDone .mat-step-label::after {
    content: "";
    border-left: 25px solid $main-color;
}

.stepDone .mat-step-label::before {
    content: "";
    border-left: 25px solid $main-color;
}

.stepDone .mat-step-header .mat-step-label.mat-step-label {
    color: $white !important;
}

.stepDone .mat-step-header .mat-step-label.mat-step-label i {
    color: $white;
}

.mat-step-label-selected::after {
    content: "";
    position: absolute;
    height: 100% !important;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid $main-color;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.mat-step-label-selected::before {
    content: "";
    position: absolute;
    height: 100% !important;
    left: 23px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid $dark-white2;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.mat-horizontal-content-container {
    background: $white 0% 0% no-repeat padding-box;
    border-radius: 16px;
    color: $light-blue;
    margin-top: 16.5px;
    overflow: visible !important;
    padding: 24px !important;
}

:host ::ng-deep .p-button {
    margin: 0 0.5rem 0 0;
    min-width: 10rem;
}

p {
    margin: 0;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

:host ::ng-deep .p-dialog .p-button {
    min-width: 6rem;
}

.mat-stepper-horizontal-line {
    flex: none !important;
    border: 0 !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: unset !important;
}

//End Of Stepper
input.mat-input-element {
    margin-top: -0.0625em;
    border: solid 1px $light-gray !important;
    padding: 14px !important;
}

mat-form-field {
    width: 100% !important;
}

.mat-form-field:hover {
    background-color: #fff !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: $white;
    background-color: $main-color !important;
    border-color: $main-color !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $white !important;
    border: solid 2px $main-color;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $main-color !important;
}

.cursor-pointer {
    cursor: pointer;
}

.show-button {
    width: 70px;
}

.custom-child-details {
    overflow: hidden;
    margin-bottom: 20px;
}

.header-toolbar {
    position: relative;
    top: -45px;
}

.width-70px {
    width: 70px;
}

small {
    font-family: montserrat-light;
    color: $light-blue;
}

label {
    font-family: montserrat-regular;
}

.form-control {
    width: 100%;
    height: 48px;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: absolute;
    z-index: 100;
    height: 48px;
    right: 0;
}

.btn-outline-secondary {
    color: $gray;
    border: 1px solid $light-gray2;
    border-right: unset;
}

.btn-transparent {
    background-color: transparent;
}

@media (min-width: 900px) {

    // alignment in view parent ,Employee ,child screen
    .ViewEmployee .header-box .parent,
    .parentComponent .header-box .col-12.parent,
    .childComponent .col-12.parent {
        padding-left: 5px;
        padding-right: 0;
    }

    .parentComponent .ui-dataview-content,
    .parentComponent .p-dataview-content {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .ViewEmployee .pl-60,
    .parentComponent .pl-60 {
        padding-left: 36px;
    }

    .childComponent .pl-60 {
        padding-left: 30px;
    }

    .parentComponent .col-12.text-right.direction-rtl {
        padding-right: 5px;
    }
}

@media (min-width: 768px) {
    .offset-md-0 {
        margin-left: 0 !important;
    }

    .Employee mat-step-header:nth-of-type(1) .mat-step-label {
        padding-left: 39px !important;
    }
}

.export {
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid $light-gray;
    border-radius: 8px;
    padding: 7px;
    color: $light-blue;
    margin-right: 24px;
    margin-left: 16px;

    img {
        margin-right: 8px;
    }
}

.font-size-8 {
    font-size: 8px !important;
}

body .pi {
    font-size: 1em;
}

.title {
    @extend .font-H1;
    font-family: montserrat-semibold;
    margin-bottom: 1rem;
    display: block;
}

body .ui-dataview .ui-dataview-header,
body .p-dataview .p-dataview-header {
    background-color: unset !important;
    color: $white;
    border: unset !important;
    margin-bottom: -40px;
    /* edit 9-8-2020 "shash"*/
}

.planSet {
    .mat-expansion-panel-header {
        height: 130px !important;
    }
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active,
body .p-paginator .p-paginator-pages .p-paginator-page.p-state-active {
    background-color: $main-color !important;
    color: $white !important;
}

body .p-paginator .p-dropdown {
    margin-left: 0.5em;
    height: 2.286em;
    min-width: auto;
}

.ui-dataview .ui-paginator-bottom,
p-paginator .p-paginator-bottom {
    margin: 30px 0;
}

.ui-selectbutton {
    display: inline-flex !important;
}

body .ui-button,
body .p-button {
    margin: 0;
    color: $light-blue !important;
    font-size: 14px;
    transition: background-color 0.2s, box-shadow 0.2s;
    background: $white 0% 0% no-repeat padding-box !important;
    border: 1px solid $light-gray !important;
}

body .ui-confirmdialog-acceptbutton,
body .p-confirm-dialog-accept {
    background: $light-red 0% 0% no-repeat padding-box !important;
    background-color: $light-red !important;
    color: $white !important;
    border-radius: 8px;
}

body .ui-confirmdialog-rejectbutton,
body .p-confirm-dialog-reject {
    border: unset !important;
    outline: unset !important;
}

.p-confirm-dialog {
    width: 30em;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page,
body .p-paginator .p-paginator-pages .p-paginator-page {
    border-radius: 8px !important;
    background: $main-color-26 0% 0% no-repeat padding-box !important;
    color: $main-color2;
    margin: 0 5px !important;
}

.ui-paginator .ui-paginator-icon,
.p-paginator .p-paginator-icon {
    color: $main-color2;
}

body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-button-icon-left,
body .p-selectbutton .p-button.p-state-active:not(.p-state-disabled):hover .p-button-icon-left {
    color: $white !important;
}

body .ui-selectbutton .ui-button.ui-state-active,
body .p-selectbutton .p-button.p-state-active {
    background-color: $main-color !important;
    border-color: $main-color !important;
    color: $white !important;
}

body .ui-selectbutton .ui-button .ui-button-icon-left,
body .p-selectbutton .p-button .p-button-icon-left {
    color: $light-blue !important;
}

body .ui-paginator .ui-paginator-current,
body .p-paginator .p-paginator-current {
    display: none;
}

.exportBTN:hover {
    color: $white !important;
    text-decoration: none;
    background-color: $light-blue !important;
    outline: 0;
}

.grid-count {
    color: $light-blue;
    font-family: montserrat-regular;
    font-size: 14px;
    position: relative;
    top: 13px;
}

.exportBTN:focus,
.exportBTN.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.child-detail__table .ui-paginator .ui-paginator-current,
.child-detail__table .p-paginator .p-paginator-current {
    display: none !important;
}

// Collapse
.mat-expansion-indicator {
    background-color: $dark-white2;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.custom-bread-crumb {
    padding: 10px 0 !important;
    background-color: unset !important;
    text-transform: uppercase;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 25px;
    list-style: none;
    background-color: unset;
    border-radius: 0.25rem;
}

body a {
    color: $main-color !important;
    cursor: pointer;
}

body a.button {
    color: $white !important;
}

.breadcrumb a,
.breadcrumb i {
    color: $light-blue !important;
    margin-left: 5px;
    margin-right: 5px;
}

.breadcrumb-item:last-child a {
    color: $main-color !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: none;
}

.mat-expansion-panel-header {
    padding: 0 10px !important;
    font-size: 18px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-bottom: 4px;
}

.ng-select-disabled .ng-value {
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter-transparent {
    width: 44px;
    height: 107px;
    background: transparent;
    position: absolute;
    left: 0;
    cursor: pointer;
    bottom: -9rem;

    .filter-sapn {
        position: absolute;
        left: 19%;
        bottom: 22%;
        transform-origin: 0 0;
        transform: rotate(-90deg);
    }

    .filter-img {
        position: absolute;
        bottom: 20%;
        left: 25%;
    }
}

.multi-collapse {
    border-radius: 7px;
    border: 1px solid $light-gray;
    padding: 20px;

    mat-panel-title {
        color: $light-blue;
    }

    .mat-expansion-indicator {
        background: $white 0% 0% no-repeat padding-box !important;
        border: 1px solid $main-color !important;
    }

    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
        color: $main-color;
    }

    .plan mat-expansion-panel {
        background: $dark-white2 0% 0% no-repeat padding-box;
        border: 1px solid $light-gray;
        border-radius: 0px 0px 9px 9px !important;
        padding: 10px;
    }
}

.child-details__container .child-detail__header {
    color: $main-color !important;
}

.delete-icon {
    width: 32px;
    height: 32px;
    position: relative;
    margin-top: 2.4rem;

    .far {
        position: absolute;
        left: -1px;
        top: 8px;
    }
}

.btn-outline-secondary {
    border: 0;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.box p label,
label {
    color: $light-blue !important;
}

.height-special {
    height: 48px;
}

.mr-rem {
    margin-right: 0.5rem;
}

.custom-form-grid {
    margin: 1em 0;
}

.forget-pssword__formSupmitted {
    text-align: center;
    margin-top: 20px;
}

.custom-p-meesage_message {
    position: relative;
    top: -25px;
}

.grid-custom-size {
    padding-right: 15px;
    padding-left: 15px;
}

// calender
mat-form-field>.mat-mdc-text-field-wrapper>.mat-mdc-form-field-flex {
    border: 1px solid rgb(212, 212, 234) !important;
    border-radius: 8px;

}

mat-form-field>.mat-mdc-text-field-wrapper>.mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    border-top-width: 0;
    padding-top: 0.08rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    height: 47px;
    min-height: 47px;
    padding: 0 !important;
}

mat-form-field>.mat-mdc-text-field-wrapper>.mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-input-element {
    border-color: transparent !important;
    padding-inline-end: 0 !important;
}

.mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    padding: 0 10px 0 0;
    background: transparent;
}

.mat-form-field>.mat-mdc-text-field-wrapper>.mat-mdc-form-field-underline {
    display: none;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent;
}

.mdc-line-ripple {
    display: none;
}

.mat-mdc-text-field-wrapper {
    padding: 0 0 0 0 !important;

}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    background-color: #fff;
}

.mdc-text-field--disabled.mdc-text-field--filled {

    background-color: #fafafa !important;
}

.mobile-gridCount {
    display: none;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #007ad9 !important;
    color: #ffffff !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    padding-inline-end: 0;
    width: 21px !important;
    height: 21px !important;
    padding: 0 !important;

    &:hover {
        background: none;
    }

}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 21px;
    height: 21px;
}

.mat-mdc-form-field-icon-suffix {
    padding: 0 !important;
}

.mat-datepicker-input {
    font-size: 14px !important;
    padding-inline-end: 0 !important;
}

.mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: #fff;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
    width: 14px !important;
    height: 14px !important;
}

.mat-mdc-slider {
    width: 100% !important;
}


.mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #34a8be;
    --mdc-switch-selected-handle-color: #34a8be;
    --mdc-switch-selected-hover-state-layer-color: #34a8be;
    --mdc-switch-selected-pressed-state-layer-color: #34a8be;
    --mdc-switch-selected-focus-handle-color: #34a8be;
    --mdc-switch-selected-hover-handle-color: #34a8be;
    --mdc-switch-selected-pressed-handle-color: #34a8be;
    --mdc-switch-selected-focus-track-color: #34a8be;
    --mdc-switch-selected-hover-track-color: #34a8be;
    --mdc-switch-selected-pressed-track-color: #34a8be;
    --mdc-switch-selected-track-color: #34a8be;
}

.mat-mdc-slide-toggle {
    --mdc-switch-disabled-selected-handle-color: #34a8be;
    --mdc-switch-disabled-unselected-handle-color: #34a8be;
    --mdc-switch-disabled-selected-track-color: #34a8be;
    --mdc-switch-disabled-unselected-track-color: #424242;
    --mdc-switch-unselected-focus-state-layer-color: #34a8be;
    --mdc-switch-unselected-pressed-state-layer-color: #424242;
    --mdc-switch-unselected-hover-state-layer-color: #34a8be;
    --mdc-switch-unselected-focus-track-color: #34a8be;
    --mdc-switch-unselected-hover-track-color: #34a8be;
    --mdc-switch-unselected-pressed-track-color: #34a8be;
    --mdc-switch-unselected-track-color: #e0e0e0;
    --mdc-switch-unselected-focus-handle-color: #34a8be;
    --mdc-switch-unselected-hover-handle-color: #34a8be;
    --mdc-switch-unselected-pressed-handle-color: #212121;
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-unselected-handle-color: #34a8be;
    --mdc-switch-selected-icon-color: #fff;
    --mdc-switch-disabled-selected-icon-color: #fff;
    --mdc-switch-disabled-unselected-icon-color: #fff;
    --mdc-switch-unselected-icon-color: #fff;
}


/* Responsive */

@media screen and (max-width: 991px) {
    .custom-grid-count {
        display: none;
    }

    .form-side {
        margin-top: 0 !important;
    }

    .col-6 .toolbar-grid {
        display: none !important;
    }

    .totalRec {
        position: relative;
        top: 15px;
    }

    .grid-count,
    body .ui-paginator .ui-dropdown,
    body .p-paginator .p-dropdown {
        position: relative;
        top: 0;
        left: 0;
    }

    .ui-menu,
    .p-menu {
        width: auto !important;
    }

    .text-center-s {
        text-align: center;
    }

    .mat-horizontal-stepper-header-container {
        display: block !important;
    }

    .mat-step-header {
        margin-bottom: 10px !important;
    }

    .mat-step-label::before {
        display: none !important;
    }

    mat-step-header:nth-of-type(1) .mat-step-label {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    mat-step-header:nth-of-type(2) .mat-step-label {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    mat-step-header:nth-of-type(3) .mat-step-label {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    mat-step-header:nth-of-type(3) .mat-step-label {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    mat-step-header:nth-of-type(4) .mat-step-label {
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
    }

    mat-step-header:nth-of-type(5) .mat-step-label {
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
    }

    .mat-step-label-selected::after,
    .mat-step-label::after {
        right: 1px !important;
    }

    .child-details__container .child-name {
        margin-top: 16px;
    }

    body .ui-paginator .ui-paginator-current {
        top: -29px;
    }

    .ui-paginator .ui-dropdown,
    .ui-paginator .p-dropdown {
        top: -1.8rem;
    }

    .text-right-s {
        text-align: right !important;
    }

    .filter {
        bottom: 0rem !important;
    }

    .grid input,
    .grid .ui-dropdown,
    .grid .p-dropdown {
        max-width: 60px !important;
        min-width: unset !important;
    }

    ui-datatable-header {
        display: block !important;
    }

    .breadcrumb {
        margin-top: 25px;
    }

    .border-right {
        border-right: 0 !important;
        border-bottom: 1px solid #dee2e6 !important;
        padding-bottom: 1rem !important;
    }

    .breadcrumb li {
        font-size: 16px;
    }

    .mat-expansion-panel-header {
        font-size: 15px;
    }
}

.d-none-web {
    display: none;
}

@media screen and (max-width: 767px) {

    .labelTab-2,
    .labelTab {
        font-size: 14px;
    }

    .d-none-mobile {
        display: none;
    }

    .d-none-web {
        display: block;
    }

    .filter {
        height: 10px !important;
    }

    .button-3 {
        padding: 12px 20px;
    }

    .labelTab-4,
    .button-3 {
        font-size: 12px;
    }

    .col-md-9 {
        flex: 0 0 75% !important;
        max-width: 75% !important;
    }

    .currentLang {
        display: none;
    }

    .parent {
        display: block;
    }

    body .ui-dialog,
    body .p-dialog {
        padding: 10px;
    }

    nav .side-right .menu {
        margin: 0 0.3rem !important;
    }

    .filter {
        background: unset !important;
        left: unset !important;
        bottom: unset !important;
        right: -88% !important;
        top: 9.7rem !important;
    }

    .filter .filter-sapn {
        left: 45% !important;
        bottom: unset !important;
        transform-origin: unset !important;
        transform: unset !important;
    }

    .filter .filter-img {
        position: unset !important;
        bottom: unset !important;
        left: unset !important;
    }

    .table th,
    .table td {
        border-top: unset !important;
    }

    .toolbar-grid {
        height: 5rem;
    }

    .pl-60 {
        padding-left: 0;
    }

    body .ui-table.ui-table-responsive .ui-paginator-bottom,
    body table,
    body .p-datatable.p-table-responsive .p-paginator-bottom,
    body table {
        border-top: unset;
    }

    .list-buttons {
        display: block;

        li {
            margin-bottom: 0.5rem;
        }
    }

    .m-2 {
        margin: 0.5rem 0 !important;
    }

    .child-details__container .add-new-child mat-icon {
        display: none;
    }

    .child-details__container .add-new-child {
        margin-right: 1rem !important;
        font-size: 12px;
        margin-top: 5px;
    }

    .backButton {
        margin-bottom: 1rem !important;
    }

    .added-box {
        margin-bottom: 1rem !important;
    }

    .nextButton,
    .backButton,
    .button-padding {
        padding: 12px 42px !important;
        width: 10rem;
    }

    // .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    //     padding: 0 .2rem !important;
    // }
    .custom-counter__txt {
        width: 70px !important;
    }
}

@media screen and (max-width: 600px) {
    .filter {
        right: 29px !important;
    }

    .toolbar-grid {
        li {
            margin-right: 0 !important;
        }
    }

    .mobCenter {
        margin: auto;
    }
}

@media screen and (max-width: 400px) {
    .filter {
        right: 29px !important;
    }

    .custom-filter-pos {
        top: -2.4rem !important;
    }

    .toolbar-grid {
        display: table;
        margin-bottom: 0rem;
    }

    .over-width {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 6rem;
    }

    img[src$="editBlue.svg"] {
        width: 19px;
    }
}

@media screen and (max-width: 320px) {
    .filter {
        right: 29px !important;
    }
}

//-------------------SHASH----------//
tr {
    color: $light-blue;
    font-family: montserrat-regular;
}

.ng-select .ng-select-container {
    border-radius: 8px;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    padding: 5px !important;
}

.form-check-inline {
    height: 48px;
}

.form-group {
    margin-bottom: 20px;
}

.dropdown-menu .dropdown-item {
    font-size: 14px;
    line-height: 36px;
    font-family: montserrat-regular-ar !important;
}

.dropdown-menu img {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important;
    max-width: 22px;
}

.dropdown-menu [ng-reflect-router-link="/third-party-notice/third-part"] img {
    margin-right: 19px;
}

.dropdown-menu {
    min-width: 200px;
    border-radius: 8px;
}

.ico-menu-stakeholder,
.ico-menu-service,
.ico-menu-registration,
.ico-menu-crm,
.ico-menu-companies,
.ico-menu-hr,
.ico-menu-session,
.ico-menu-area,
.ico-menu-settings,
.ico-menu-behavior {
    background-repeat: no-repeat;
    background-position: 17px center;
    padding: 16px 17px 16px 50px;
    border-radius: 12px;
}

.ico-menu-stakeholder {
    background-image: url('~assets/images/menu/ico-menu-stakeholder.png');
}

.ico-menu-stakeholder:hover,
.ico-menu-stakeholder[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-stakeholder-hover.png');
}

.ico-menu-service {
    background-image: url('~assets/images/menu/ico-menu-service.png');
    padding-left: 57px;
}

.ico-menu-service:hover,
.ico-menu-service[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-service-hover.png');
}

.ico-menu-registration {
    background-image: url('~assets/images/menu/ico-menu-registration.png');
}

.ico-menu-registration:hover,
.ico-menu-registration[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-registration-hover.png');
}

.ico-menu-crm {
    background-image: url('~assets/images/menu/ico-menu-crm.png');
}

.ico-menu-crm:hover,
.ico-menu-crm[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-crm-hover.png');
}

.ico-menu-companies {
    background-image: url('~assets/images/menu/companies.png');
}

.ico-menu-companies:hover,
.ico-menu-companies[aria-expanded="true"] {
    background-image: url('~assets/images/menu/companies-hover.png');
}

.ico-menu-hr {
    background-image: url('~assets/images/menu/ico-menu-hr.png');
}

.ico-menu-hr:hover,
.ico-menu-hr[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-hr-hover.png');
}

.ico-menu-session {
    background-image: url('~assets/images/menu/ico-menu-session.png');
}

.ico-menu-session:hover,
.ico-menu-session[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-session-hover.png');
}

.ico-menu-settings {
    background-image: url('~assets/images/menu/ico-menu-settings.png');
}

.ico-menu-settings:hover,
.ico-menu-settings[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-settings-hover.png');
}

.ico-menu-area {
    background-image: url('~assets/images/menu/ico-menu-area.png');
}

.ico-menu-area:hover,
.ico-menu-area[aria-expanded="true"] {
    background-image: url('~assets/images/menu/ico-menu-area-hover.png');
}

.ico-menu-behavior {
    background-image: url('~assets/images/menu/ico-menu-behavior.svg');
    padding-left: 67px;
}

.ico-menu-behavior:hover,
.ico-menu-behavior[aria-expanded="true"] {
    background-image: url('~assets/images/menu/behavior-hover.svg');
}

.owl-theme .owl-nav {
    margin-top: 0;
}

.owl-carousel {
    padding-top: 0px;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0px;
}

.dropdown-item {
    direction: ltr;
    text-align: left;
}

main[dir="rtl"] .dropdown-item {
    text-align: right;
    direction: rtl;
}

main[dir="rtl"] {
    .switch input:checked~small:after {
        content: "نشط" !important;
    }

    .switch small:after {
        content: "غير نشط" !important;
    }

    .switch small {
        width: 160px !important;
    }
}



.slider-container {
    margin-bottom: 20px;
}

.owl-carousel.owl-drag .owl-item:last-child {
    padding-right: unset;
}

.owl-carousel.owl-drag .owl-item {
    margin: 0;
}

.ng-select-container,
.ng-select-container input {
    height: auto !important;
}

.loading-section {
    height: 100vh;
    border-radius: 8px;
    width: 100%;

    img {
        margin: 10% auto;
    }

    p-progressSpinner {
        position: absolute;
        bottom: 70%;
    }
}

.child-details__container p-radiobutton .ui-label-disabled,
.child-details__container p-radiobutton .p-label-disabled {
    color: $dark-white !important;
}

.child-details__container p-radiobutton .ui-state-disabled,
.child-details__container p-radiobutton .p-state-disabled {
    border-color: $dark-white !important;
    background-color: $dark-white !important;
    color: $white;
}

.display-none {
    display: none !important;
}

@keyframes ui-progress-spinner-color {

    100%,
    0% {
        stroke: $heavy-red;
    }

    40% {
        stroke: $open-blue;
    }

    66% {
        stroke: #008744;
    }

    80%,
    90% {
        stroke: $yellow;
    }
}

@media (max-width: 800px) {

    body .ui-dataview .ui-dataview-header,
    body .p-dataview .p-dataview-header {
        margin-bottom: 0px;
        // height: 70px;
    }

    .mat-horizontal-stepper-header-container {
        margin-left: 0.5rem;
        margin-right: 1rem;
    }

    .Employee .mat-horizontal-stepper-header-container {
        margin-left: 0.5rem;
        margin-right: 2rem;
    }

    .mat-horizontal-content-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .over-width {
        max-width: 20rem;
    }

    .ViewEmployee .header-box {
        padding-left: 0px !important;
    }

    .thirdPartyNotice .wrapper-tabel,
    .ViewEmployee .wrapper-tabel {
        margin-right: 0px !important;
    }

    .child-container .header-box {
        padding-left: 26px !important;
    }

    .button-padding {
        padding: 12px 60px !important;
    }

    .parentComponent .wrapper-tabel {
        margin-right: 0px;
    }

    .parentComponent .parent {
        padding-right: 0px !important;
    }

    .parentDetails .over-width {
        max-width: 10rem;
    }

    .thirdPartyNotice .toolbar-grid li:nth-child(3),
    .serviceBooking .toolbar-grid li:nth-child(3),
    .Contract .toolbar-grid li:nth-child(3),
    .Session .toolbar-grid li:nth-child(3) {
        align-self: center;
    }
}


/**** esponsive  for mobile  nermen ****/

.thirdPartyNotice .list-buttons .backButton,
.serviceBooking .list-buttons .backButton,
.Contract .list-buttons .backButton,
.Session .list-buttons .backButton {
    padding: 12px 35px !important;
}

@media (max-width: 426px) {

    p,
    label {
        font-size: 12px;
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 0 .5rem !important;
    }

    .Employee .parent,
    .SERVICEBOOKING .parent .thirdPartyNotice .parent {
        margin-top: 0 !important;
    }

    .Employee .added-box {
        margin-top: 10px !important;
    }

    .Employee .nextButton,
    .Employee .backButton {
        width: 10rem !important;
    }

    .Employee .btn-danger {
        margin-bottom: 10px;
    }

    .saveAdone {
        padding: 12px 0;
    }

    .Employee mat-step-header:nth-of-type(1) .mat-step-label {
        padding-left: 39px !important;
    }

    .Employee .parent {
        margin-top: 0px !important;
    }

    .mat-horizontal-stepper-header-container {
        margin-right: 1rem;
    }

    .over-width {
        max-width: 10rem;
    }

    .button-padding {
        padding: 12px 34px !important;
    }

    .form-container .form-group .forgetPass {
        margin-bottom: 1rem;
    }

    table {
        font-size: 12px;
    }

    .breadcrumb {
        display: none !important;
    }

    .thirdPartyNotice .list-buttons,
    .serviceBooking .list-buttons,
    .Contract .list-buttons {
        justify-content: space-between;
        display: flex !important;
    }

    .thirdPartyNotice .list-buttons .backButton,
    .serviceBooking .list-buttons .backButton,
    .Contract .list-buttons .backButton {
        padding: 12px 35px !important;
        width: auto !important;
    }
}

@media (max-width: 376px) {

    .Employee .nextButton,
    .Employee .backButton {
        width: 7rem !important;
    }

    .ViewEmployee .ui-dataview-header .col-12.text-right.direction-rtl {
        padding-left: 0;
        padding-right: 3px;
    }

    .over-width {
        max-width: 7rem;
    }
}

// add my nermen in sprint 4 for thrid party confirmdialog
body .ui-dialog .ui-dialog-footer button.ui-confirmdialog-acceptbutton,
body .p-dialog .p-dialog-footer button.p-confirm-dialog-accept {
    background: $light-red 0% 0% no-repeat padding-box !important;
    background-color: $light-red !important;
    border-color: $light-red !important;
    color: $white !important;
    font-weight: bold;

    .p-button-icon-left {
        display: none;
    }
}

body .ui-dialog .ui-dialog-footer button.ui-confirmdialog-rejectbutton,
body .p-dialog .p-dialog-footer button.p-confirm-dialog-reject {
    background: $white 0% 0% no-repeat padding-box !important;
    background-color: $white !important;
    border-color: $white !important;
    color: black !important;
    font-weight: bold;

    .p-button-icon-left {
        display: none;
    }
}

body .ui-dialog .ui-confirmdialog-message,
body .p-dialog .p-confirm-dialog-message {
    font-size: 1rem;
    direction: ltr;
}

// END
// add by nermen for responsive in third party grid
@media (max-width: 768px) {

    .thirdPartyNotice .header-box,
    .serviceBooking .header-box,
    .Contract .header-box {
        padding-left: 10px !important;
        padding-right: 1rem !important;
    }

    .custome-pos-toolbar-grid {
        position: relative;
        margin-bottom: 3rem !important;
        left: 0;
        float: left;
        direction: ltr;
    }

    .mat-expansion-panel-body {
        padding: 0 8px 16px !important;
    }

    body .ui-dialog,
    body .p-dialog {
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .my-sm-3 {
        margin: .5rem 0 !important;
    }

    //   .mat-expansion-panel-body {
    //     padding: 5px !important;
    //     overflow: hidden;
    // }
    .title {
        font-size: 14px;
    }

    .thirdPartyNotice table>tr>th,
    body .ui-table .ui-table-thead>tr>th,
    .table-striped tr>th,
    body .p-datatable .p-datatable-thead>tr>th {
        width: 10rem;
    }

    // service follow up
    .toolbar-grid.plneSet_session.show-text>li {
        display: none;
    }

    .servicefollowup_session .toolbar-grid {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 426px) {
    .actions img {
        // for general icon in grid
        width: 17px;
        height: 17px;
    }

    mat-panel-title .progress-status {
        position: relative !important;
        left: 1rem !important;
        right: 1rem !important;
        top: -1rem !important;
    }

    .box {
        padding: 16px 5px;
    }

    .toolbar-grid {
        display: flex;
    }

    .toolbar-grid li:nth-child(2n) {
        margin-right: 0.7rem !important;
    }

    .toolbar-grid li:nth-child(3n) {
        display: none;
    }

    .servicefollowup_session .toolbar-grid li:nth-child(3n) {
        display: block;
    }

    .thirdPartyNotice .toolbar-grid {
        margin-bottom: 0rem;
    }

    .thirdPartyNotice p-paginator .ui-widget {
        margin-top: 20px;
    }

    // service follow up
    .session_plan {
        width: 100%;
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

// add by nermen for responsive service booking
.serviceBooking .wrapper-tabel,
.Contract .wrapper-tabel,
.Session .wrapper-tabel {
    margin-right: 58px;
    padding-right: 0px !important;
}

@media (max-width: 768px) {

    .serviceBooking.wrapper,
    .Contract.wrapper,
    .Session.wrapper {
        margin: 1rem !important;
    }

    .serviceBooking .wrapper-tabel,
    .Contract .wrapper-tabel,
    .Session .wrapper-tabel {
        margin-right: 0px !important;
        padding-right: 0px !important;
        margin-left: 3px;
    }
}

@media (max-width: 426px) {

    .SERVICEBOOKING .parent,
    .Contract .parent,
    .Session .parent {
        margin-top: 0 !important;
    }

    .grid-count,
    body .ui-paginator .ui-dropdown,
    body .p-paginator .p-dropdown {
        margin-top: 0rem;
    }

    .servicefollowup_actionlog .ui-paginator .ui-dropdown,
    .servicefollowup_session .ui-paginator .ui-dropdown,
    .servicefollowup_actionlog .ui-paginator .p-dropdown,
    .servicefollowup_session .ui-paginator .p-dropdown {
        margin-top: 0;
    }

    .toolbar-grid {
        height: 4rem;
    }
}

@media (max-width: 320px) {

    .grid-count,
    body .ui-paginator .ui-dropdown,
    body .p-paginator .p-dropdown {
        margin-top: 1rem;
    }
}

// add by nermen
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 1.7rem !important;
}

@media (max-width: 426px) {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 0 1rem !important;
    }
}

@media screen and (max-width: 600px) {
    .card-characteristics {
        padding: 1rem .3rem;
    }

    .servicesFollowup_button {
        .mat-button-toggle-group-appearance-standard {
            .mat-button-toggle-button {
                .mat-button-toggle-label-content {
                    width: 3rem;

                    .action_buutons {
                        display: none;
                    }
                }
            }
        }

        .servicesFollowup_button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
            padding: 0 1rem !important;
        }
    }

    //  .mat-select-panel{
    //     transform: scale(.8,0.8) !important
    //  }
}

// p-multiselect .p-multiselect-label,
p-multiselect .ui-multiselect-label {
    max-width: 160px !important;
}

.hideSpan ng-select .ng-option-disabled {
    display: none !important;
}

.e-schedule-table .e-time-slots span {
    font-family: montserrat-regular-ar !important;
    font-weight: bold !important;
}

.e-toolbar-items .e-tbar-btn-text {
    font-family: montserrat-regular-ar !important;
}

.e-content-wrap .e-appointment-details .e-subject {
    font-family: montserrat-regular-ar !important;
}

.e-content-wrap .e-appointment-details .e-time {
    font-family: montserrat-regular-ar !important;
}

.planSet {
    border-radius: 20px;

    .mat-expansion-panel-body {
        background: #fff !important;
    }

    .mat-expansion-panel {
        background: #fff !important;
    }

    .mat-expansion-panel-header.mat-expanded {
        background: #fff !important;
    }
}

.servicePlans:first-child {
    .mat-expansion-panel-header {
        height: 95px !important;
    }

    .mat-expansion-panel-header.mat-expanded {
        background: #34A8BE;
        color: #fff !important;

        span {
            color: #fff !important;
        }

        .control-label {
            color: #fff !important;
        }
    }
}

.process-container {
    .mat-expansion-panel-header:first-child {
        height: unset !important;
    }

    .mat-expansion-panel-header.mat-expanded:first-child {
        background: unset;
        color: unset !important;

        span {
            color: unset !important;
        }

        .control-label {
            color: #7674A1 !important;
        }
    }
}

.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
}

.addGroup {
    .mat-expansion-panel {
        overflow: unset !important;
    }

    .mat-content {
        overflow: unset !important;
    }

    .mat-expansion-panel-header {
        height: 150px !important;
    }
}

.Contract {
    .mat-expansion-panel {
        overflow: unset !important;
    }

    .mat-content {
        overflow: unset !important;
    }
}

.attendance .ui-table-resizable>.ui-table-wrapper,
.attendance .p-datatable-resizable>.p-datatable-wrapper {
    overflow-x: unset !important;
}

.servicePlans {
    .mat-expansion-panel {
        background: #F6F7FF;
    }

    .mat-expansion-panel-header:first-child {
        height: 95px !important;
    }

    .mat-expansion-panel-header.mat-expanded:first-child {
        background: #34A8BE;
        color: #fff !important;

        span {
            color: #fff !important;
        }

        .control-label {
            color: #fff !important;
        }
    }
}

label {
    padding: 0px 0.5rem 0px 1em;
}

.mat-horizontal-stepper-header {
    pointer-events: none !important;
}

.ng-clear {
    margin-left: 6px;
}

.ng-value-container {
    padding-bottom: 5px !important;
}

.childAttendance {

    .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
    .e-schedule .e-schedule-toolbar .e-active-view .e-icons,
    .e-schedule .e-vertical-view .e-header-cells.e-current-day,
    .e-schedule .e-month-view .e-appointment .e-appointment-details .e-time,
    .e-schedule .e-month-view .e-appointment .e-appointment-details .e-subject {
        color: #fff !important;
    }

    .e-time {
        display: none !important;
    }

    .e-delete {
        display: none !important;
    }

    .e-edit {
        display: none !important;
    }

    .e-close {
        padding: 0 !important;
    }

    .e-popup-content {
        display: none !important;
    }

    .e-work-cells {
        .e-appointment {
            opacity: .5;
        }

        &:hover {
            .e-appointment {
                opacity: 1;
            }
        }
    }
}


/* width */

.beneficiary-carousel::-webkit-scrollbar {
    width: 10px !important;
    height: 5px !important;
}


/* Track */

.beneficiary-carousel::-webkit-scrollbar-track {
    background: var(--color-surface-light) !important;
}


/* Handle */

.beneficiary-carousel::-webkit-scrollbar-thumb {
    background: var(--color-primary) !important;
}


/* Handle on hover */

.beneficiary-carousel::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary) !important;
}

.mat-step-text-label {
    overflow: visible !important;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-left: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    background: none;

    &:hover {
        background: #d6d6d6;
    }
}

.mat-tab-body.mat-tab-body-active,
.mat-tab-body,
.mat-tab-body-wrapper,
.mat-tab-body-content {
    overflow: visible !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #34a8be !important;
    background: #fff !important;
}

.p-component {
    font-family: montserrat-regular-ar !important;
}

.p-overlay {
    left: 0 !important;
    top: 35px !important;
}

.small-circule {
    margin-right: 5px;
    margin-left: 0;
}

p.result-p {
    margin: 0 !important;
}

td ol {
    padding-inline-start: 10px !important;
}

.mat-radio-button {
    display: block !important;
}

.blue-chip {
    padding: 12px;
    margin: 12px;
    background-color: #34A8BE80;
    color: #34A8BE;
    border-radius: 8px;
}

.blue_title {
    color: $main-color !important
}

.custom_loader_overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(74, 74, 74, .8);
    border-radius: 16px;
}

.custom_loader_img {
    position: absolute;
    width: 20%;
    max-width: 120px;
    // height: 4rem;
}

ktd-grid .ktd-grid-item-placeholder {
    background: transparent !important;
}

.cal-week-view .cal-header.cal-today {
    color: #34a8be !important;
    background: #fff !important;
}

.p-inputtext {
    border: none;
}

.cal-week-view .cal-event {
    color: #fff !important;
}

.title-font-size {
    font-size: 20px;
    display: flex;
    flex-direction: column;

    .title-sub {
        font-size: 10px;
        color: #7674A1;
        margin-top: 4px;
    }
}

.card-actions {
    display: flex;
    align-items: center;

    .toggle-expand {
        width: 32px;
        height: 32px;
        border: 1px solid #AEAEAE;
        border-radius: 50%;
        margin-inline-end: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .remove-action {
        width: 16px;
        cursor: pointer;
    }
}

.card-numbering {
    display: flex;
    align-items: center;

    span {
        margin-bottom: 0;
    }
}

.emergency-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    .emergency-plan-label {
        font-size: 16px;
        color: #7674A1;
        margin-bottom: 23px;
    }

    .emergency-plan-status-active {
        font-size: 16px;
        color: #fff;
        background-color: #28C76F;
        border-radius: 30px;
        padding: 8px;
        margin-bottom: 30px;
    }

    .emergency-plan-status-inactive {
        font-size: 16px;
        color: #fff;
        background-color: #ea5455;
        border-radius: 30px;
        padding: 8px;
        margin-bottom: 30px;
    }
}

.situationsDataView {
    background: #F6F7FF;
    padding: 20px;
}

.referral-actions {

    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard {
        border: 1px solid #34A8BE !important;
        border-radius: 8px !important;
        width: 100%;
        // justify-content: space-evenly;
        justify-content: space-between;
        /* Distribute space evenly between items */
        align-items: stretch;
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 0 12px !important;
        color: #34A8BE;
        font-size: 10px;
        font-weight: 400;
    }

    .mat-button-toggle {
        flex: 1
    }
}

main[dir=rtl] .referral-actions {
    .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggl {
        border-right: 1px solid #34A8BE !important;
    }
}

main[dir=ltr] .referral-actions {
    .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggl {
        border-left: 1px solid #34A8BE !important;
    }
}

.impacts-container {
    .mat-expansion-panel-content {
        background: rgba(52, 168, 190, 0.1);
    }

    .mat-expansion-panel-body {
        margin: 0;
    }



    td {
        span {

            font-family: "montserrat-light-ar" !important;
        }
    }
}

.action-icon {
    width: 12px;
    height: 12px;
}

.mark-deleted {
    color: rgba(255, 0, 0, 0.3) !important;
}

.gray-backgroud .fa-angle-down:before,
.gray-backgroud .fa-angle-up:before {
    color: rgba(0, 0, 0, .54);
}

.branch-details {
    .mat-expansion-panel-body {
        padding: 24px 16px !important;
        border-top: 1px solid #dee2e6;
    }
}

.attachment-chip {
    display: flex;
    background: #fff;
    width: fit-content;
    align-items: center;
    justify-content: center;
    border: 1px solid #D4D4EA;
    border-radius: 26px;
    padding: 8px 16px;
    margin-inline-end: 4px;
    margin-top: 4px;

    p {
        margin-bottom: 0 !important;
        margin-inline-start: 4px;
        margin-inline-end: 16px;
        color: #7674A1;
    }

    a {
        text-decoration: none;
    }
}

.mat-mdc-slide-toggle {

    --mdc-switch-unselected-focus-track-color: #e0e0e0 !important;
}